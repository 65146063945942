$offset: 80px;

.cta-component {
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  width: calc(100vw);

  &__gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(270deg, rgba(30, 40, 60, 0.00) 0%, $colors__black 100%);

    &--content {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }
  }

  &__text {
    position: relative;
    @include container();
    //padding-top: 72px;
    //padding-bottom: 72px;

    padding-left: 60px;
    color: $colors__white;

    &__header {
      @extend .font__h2;
      margin-bottom: 32px;
    }

    &__body {
      @extend .font__body;
      margin-bottom: 32px;

      p {
        @extend .font__body;
      }

      blockquote {
        @extend blockquote;
        margin-left: 0;
      }
    }

    &__cta-button {}
  }

  @include above(desktop) {

    //overflow-y: scroll;
    &__text {
      &--left {
        padding-right: 40%;
      }

      &--right {
        padding-left: 40%;
      }

    }

    &__text {
      margin-top: 18px;
    }

    &__cta {
      margin-top: $desktop-vertical-padding;
    }
  }
}

.cta-component-image {
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  width: calc(100vw);
  max-width: 1440px;

  &--full {
    justify-content: center;

    .cta-component-image__image {
      display: none;
    }

    .cta-component-image__text {
      justify-content: center;
    }

    .cta-component-image__gradient,
    .cta-component-image__text:not(.carousel__controls),
    .cta-component-image__image {
      min-height: 500px;
    }
  }

  &--offset {

    background-position: 0px calc(-#{$offset} * 2);
    background-repeat: no-repeat;
    justify-content: flex-end;

    .cta-component-image__text {
      margin-top: $offset;
      margin-right: $offset;
    }
  }

  &--half,
  &--two-thirds {
    .cta-component-image__text {
      justify-content: start;
    }

    .cta-component-image__text:not(.carousel__controls) {
      min-height: 500px;
    }
  }

  &--half:not(.cta-component-image--offset) {
    .cta-component-image__text.cta-component-image__text {
      justify-content: center;
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  &__gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-image: linear-gradient(270deg, rgba(30, 40, 60, 0.00) 0%, $colors__black 100%);

    &--content {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }

    &--left {
      background-image: linear-gradient(90deg, rgba(30, 40, 60, 0.00) 0%, $colors__black 100%);      
    }

    &--right {      
      background-image: linear-gradient(270deg, rgba(30, 40, 60, 0.00) 0%, $colors__black 100%);
    }
  }

  &__image {

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50%;
    width: 100%;

    &__image {
      padding: 20px;

      img {
        width: 100%;
      }
    }
  }

  &__text {
    z-index: 2;
    display: flex;
    flex-direction: column;

    @include themify($themes) {
      background: themed('color');
    }

    &__read {
      align-self: flex-start;
    }

    &__date {
      margin-bottom: 12px;
    }

    &__eyebrow {
      margin-bottom: 12px;
    }

    &__header {
      @extend .font__h2;
    }

    color: $colors__white;
    padding: 40px 50px;

    &--half,
    &--two-thirds {
      padding-bottom: 100px;
    }

    &__header {
      @extend .font__h2;
      margin-bottom: 32px;
    }

    &__body {
      @extend .font__body;
      margin-bottom: 32px;

      p {
        @extend .font__body;
      }
    }
  }

  @include above(desktop) {
    flex-direction: row;

    &--right {
      flex-direction: row-reverse;
    }

    &--full {
      .cta-component-image__image {
        display: block
      }
    }

    &--half,
    &--two-thirds {
      .cta-component-image__text {
        //justify-content: center;
        justify-content: start;
      }
    }

    &--offset {
      background-position: 0px calc(-#{$offset});

      .cta-component-image__text {
        margin-right: 0px;
      }
    }

    &__image {
      padding-top: 66%;

      &--half {
        width: 50%;
      }

      &--two-thirds {
        width: 66.66%;
      }

      padding-top: 0;
    }

    &__text {
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 80px;

      &__dots {
        padding-left: 32px;
        padding-right: 32px;
      }

      &--half,
      &--two-thirds {
        padding-bottom: 40px;
      }

      &--two-thirds {
        width: 33.33%;
      }

      &--half {
        width: 50%;
      }
    }
  }

  @include above(desktop) {

    &__cta {
      margin-top: $desktop-vertical-padding;
    }
  }
}

.cta-component-carousel {
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  position: relative;

  &__left {
    padding-left: 60px;
  }

  &__right-arrow {
    right: 15px;
  }

  &__left-arrow {
    left: 15px;
  }

  .carousel__dot {
    color: white;
    opacity: .5;

    &--active {
      opacity: 1;
    }
  }

  .cta-component {
    margin-left: 0;
    margin-right: 0;
  }

  & &__controls {
    //padding-right: 40%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;

    .cta-component__left {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.carousel__slides-wrapper {
    width: auto;
    position: relative;
    //max-height: 500px;
  }

  &__controls {
    padding-left: 16px;
  }

  @include above(desktop) {
    .cta-component {
      margin-left: -50vw;
      margin-right: -50vw;
    }

    &__left-arrow {
      left: 20px;
    }

    &__left-arrow,
    &__right-arrow {
      font-size: 30px;
    }

    &.carousel__slides-wrapper {
      width: calc(100vw - 8px);
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }
}

.cta-custom-component {
  @include themify($themes) {
    background: themed('color');
  }
  margin: 50px 0;
  padding: 100px 0;
  text-align: center;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  

  &__text {
    @include themify($themes) {
      @if themed('color') == $colors__navy {
        color: $colors__white;
      }
      @else {
        color: $colors__black;
      }
    }   
    position: relative;
    padding-left: 60px;
    padding-right: 60px;
    @include container();

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &__header {
      font-size: 3rem;
      font-weight: $fonts__semibold;
      margin-bottom: 32px;
    }

    &__body {
      font-size: 1.2rem;
      margin-bottom: 32px;
    }

    &__cta-button {
      padding: 10px 35px;
      font-size: 16px;
      letter-spacing: .5px;
      @include themify($themes) {
        color: $colors__white;
        @if themed('color') != $colors__navy {
          background-color: $colors__navy;
          border: none;
        }
      }
      &:hover {
        padding: 14px 48px;
      } 
    }
  }
}