.flex-table {
    $input-spacing: 14px;
    width: 100%;
    table{
        table-layout: fixed;
    }
    #flexTable.table-slideDown .sticky-table-header.fixed-header{
        top:8.25rem !important;
    }
    @media all and (-ms-high-contrast:none)
    {
        #flexTable.table-slideDown.table-slideDown .flex-table__headrow {
            top: 7.93rem !important
        }
    }
    .sticky-table-header.fixed-header{
        background:white !important;    
        display:table !important;
        max-width: 70rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        position:fixed !important;
        table-layout: fixed;
        top: 4rem !important;
        width: 100% !important;
        z-index: 100 !important;
        @media all and (-ms-high-contrast:none)
        {
            .flex-table__headrow{
                background:white !important;    
                display: table;
                left: 50% !important;;
                max-width: 70rem;
                position: fixed;
                right: 0px;
                top: 63px !important;
                transform: translate3d(-50%, 0px, 0px);
                width: 100%;
                z-index: 1 !important;
            }

        }

    }
    table {
        width: 100%;

        th {
            padding-right: 0;
        }
    }

    &__filters {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $colors__lines;

        &--header {
            font-weight: 400;
        }

        .datepicker__container {
            display: inline-block;      
            
            .regular-input {
                width: 257px;
            }
        }

        .regular-input {
            min-width: 250px;

            &__datepicker {
                padding: 0;                

                &:hover {
                    outline: 1px solid $colors__teal;       
                }

                &--placeholder {
                    position: absolute;
                    pointer-events: none;
                    padding: $input-spacing;
                }

                input {
                    padding: 15px;
                }

                .vc-popover-content {
                    border: 1px solid $colors__teal;
                    border-radius: 0;
                }

                .vc-shadow {
                    box-shadow: none;
                }
            }
    
            .regular-dropdown__select {
                .icon {
                    position: absolute;
                    right: $input-spacing;
                }
            }
        }

        .multiselect {

            &--active {
                outline: 1px solid $colors__teal;
            }

            &-checkboxes {
                list-style: none;
                margin: 0;
                margin-top: 0 !important;
                padding: 0;
                position: absolute;
                left: 0;
                background: $colors__white;
                width: 100%;
                outline: 1px solid $colors__teal;
                z-index: 1;
                max-height: 0;
                opacity: 0;
                visibility: hidden;
                transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;

                &--expanded {
                    max-height: 1000px;
                    opacity: 1;
                    visibility: visible;                    
                }

                &__input {
                    display: flex;
                    margin: $input-spacing 0;
                    padding: 0 $input-spacing;
                    
                    input {                        
                        -webkit-appearance: checkbox;

                        &:checked + label:after {
                            top: 10px;                            
                        }
                    }
                }                

                &__button {                    
                    border-top: 1px solid $colors__gray--light;

                    button {
                        padding: 10px 0;
                        text-align: center;
                        width: 100%;
                        color: $colors__coral;
                        cursor: pointer;
                    }
                }
            }
        }

        .active-filters {
            list-style: none;
            padding: 0;
            margin-right: 0;

            &__filter {
                display: inline;

                .button {
                    background-color: $colors__teal;
                    color: $colors__white;
                    border: none;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    transition: background-color $hover-speed ease-in-out, color $hover-speed ease-in-out;

                    &:hover {
                        padding: 8px 20px;
                        padding-bottom: 7px;
                        margin: 0 5px 5px 0;
                        background-color: $colors__teal--dark;
                    }                    
                }

                &--reset {
                    button {
                        background-color: transparent !important;
                        color: $colors__navy--dark !important;
                        text-decoration: underline;
                        padding: 0 !important;

                        &:hover {
                            color: $colors__coral--dark !important;
                        }
                    }
                }
            }
        }

        &-container {
            display: inline-block;
        }

        .expand-filters {
            display: none;
        }
    }    

    &__headrow {

    }

    &__header {        
        cursor: pointer;
    }

    &__row {
        display: none;

        &--visible{
            display: table-row;
        }

        &--header {
            display: none;
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-right: 0;

            li {
                margin-bottom: 10px;
            }
        }

        &--bullet {
            display: none;
        }
    }

    .insights-search__more {
        margin-top: 20px;
    }

    .icon {
        display: inline-block;
        transition: transform 0.5s ease;
        
        &--flip {
            transform: rotate(180deg) !important;
        }

        &--rotate {
            transform: rotate(45deg) !important;
        }
    }

    &--mobileonly {
        display: none;
    }

    .insights-search__insights__no-results {
        margin-top: 2rem;
    }

    @include below(desktop) {
        table {
            margin-top: $input-spacing;
        }        
                
        thead tr { 
            display: none;
        }

        &__filters {
            .regular-input {
                margin-bottom: 0;
                margin-right: 0;
            }

            &--label {
                color: $colors__gray;
                font-size: 14px;
            }

            &-container {
                display: block;
                max-height: 0;
                opacity: 0;
                visibility: hidden;
                transition: max-height 0.5s ease, opacity 0.5s ease;                                

                .regular-input:first-child {
                    margin-top: 0;
                }

                &--expanded {
                    max-height: 1000px;
                    opacity: 1;
                    visibility: visible;
                    border: 1px solid $colors__teal;
                    border-top: none;
                    padding: $input-spacing;
                    padding-bottom: 0;

                    .multiselect {
                        outline: none;

                        button {
                            display: none;
                        }

                        ul {
                            position: relative;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;
                            outline: 0;
                            list-style: none;
                            padding-left: 0;
                            outline: 0;
                            border: none;    

                            .multiselect-checkboxes__button {
                                display: none;
                            }

                            .multiselect-checkboxes__input {                                
                                margin: 7px 0;                                
                                width: 50%;
                                padding: 0;

                                &--selectall {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .datepicker__container {
                        margin-top: $input-spacing;
                        width: 100%;

                        .regular-input {
                            margin-top: 0;
                            width: 100%;
                        }
                    }

                    .regular-input__datepicker {                        

                        &--placeholder {
                            display: none;
                        }

                        .vc-container.vc-relative.vc-reset {
                            width: 100%;
                            border: none;
                        }
                    }
                }

                ul.regular-input {                    
                    list-style: none;
                    padding-left: 0;
                    outline: 0;
                    border: none;                    

                    .regular-input__button {
                        padding: 7px 0;
                    }
                }

                .filter-actions {
                    display: flex;
                    justify-content: space-around;

                    button {
                        padding: $input-spacing;
                    }

                    &__apply {
                        color: $colors__coral;
                    }
                    &__reset {
                        text-decoration: underline;
                    }
                }
            }

            .expand-filters {
                display: flex;
                justify-content: space-between;                

                .regular-input {
                    min-width: auto;
                    width: calc(50% - 14px);
                    transition: width 0.5s ease;
                    margin-right: 0;

                    .icon {
                        position: absolute;
                        right: $input-spacing;
                    }

                    &--expanded {
                        width: 100%;
                        color: $colors__teal;
                        border: 1px solid $colors__teal;
                        border-bottom: none;      
                        outline: 0;                  
                    }

                    &--collapsed {
                        width: 0;                        
                        visibility: hidden;
                        margin: 0;
                    }
                }
                
                &__button {
                    padding: $input-spacing;
                    width: 100%;
                }
            }
        }

        &__row {
            margin-bottom: 0;
            border: none;

            &--title {
                font-size: 1.25rem !important;
                font-weight: bold !important;

                .flex-table__row--header {
                    display: none;
                }
            }

            &--meta {
                display: inline !important;
                width: auto !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;                
                padding-right: 0 !important;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem !important;
                color: $colors__teal;
                white-space: nowrap;

                &:first-child {
                    &:before {
                        content: '';
                        display: block;
                        margin-top: 1rem;
                    }
                }                

                &.flex-table__row--empty {
                    visibility: hidden !important;
                    span {
                        padding: 0;                    
                    }
                }

                .flex-table__row--data {
                    display: inline;
                    white-space: normal;
                }

                .flex-table__row--header {
                    display: none;
                }

                + .flex-table__row--title {
                    margin-top: 0.5rem;
                }
            }

            &--bullet {
                padding: 0 !important;
            }

            &--header {
                font-weight: 600;
                width: 40%;
            }

            &--data {
                width: 60%;
            }

            td {
                max-width: 100% !important;       
                width: 100%;         
                display: table;
                margin-bottom: calc(0.5rem + 8px);

                &:first-child {
                    margin-top: 0.5rem;                               
                }

                span {
                    display: table-cell;
                    padding: 0 10px;
                }
            }
        }

        &--mobileonly {
            display: block;
        }
    }
}