.emergency-banner {
    position: relative;        
    top: 0;
    bottom: auto;
    color: $colors__white;
    background-color: $colors__teal;
    padding: 0 $homepage-inquiry-side-padding;

    .cookie__inner {
        width: 100%;
        justify-content: space-between;
        margin: 0;
        padding: 1rem 0;

        &__text {
            margin-right: 0;
            line-height: 1.25;
            //padding-right: 1rem;
            margin-bottom: 0;

            a {
                &:hover {
                    color: $colors__white;
                }
                &:visited {
                    color: $colors__white;
                }
                &:focus {
                    color: $colors__white;
                }
            }            
        }
    }

    @include below(tablet) {
        .cookie__inner {
            width: 100%;
            margin: 0;
            display: flex;
        }
    }

    @include above(desktop) { 
        padding: 0 5vw;
    }
}