.homepage-inquiry-service-list {
    padding-top: 20px;

    border-top: 1px solid $colors__yellow;

    &:last-child {
        border-bottom: 1px solid $colors__yellow;
    }

    &__heading {
        @extend .font__subhead2;
        color: $colors__yellow;
        margin: 1em 0;
    }

    &__item {
        // display: block;
        @extend .font__body3;
        margin: .5em 0;
    }
    &__link {
        @extend .font__body3;
        transition: opacity .5s ease;
        @include fadeOnHover();
    }

    @include above(desktop){
        padding-top: 30px;
        padding-bottom: 45px;

        &__heading {
            margin: 1.5em 0;
        }

        &__item {
            margin: 0 0 1em 0;
        }

        &__list {
            column-count: 2;
        }
    }
}