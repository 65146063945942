.professional-listing {
    @extend .font__body2;
    color: $colors__black;
    margin-top: 1.7em;

    &__name {
        color: $colors__teal--dark;
        @extend .font__body2;
        @extend .font__body2--semibold;
        @include links__hover-black();
    }   

    &__link {
        //margin-bottom: 5px;
    }
    &__sub {
        @extend .font__body4;
        display:flex;
        flex-wrap: wrap;
        align-content: middle;
        margin-bottom: 5px;
    }

    &__sub-line {
  
    }
    &__item {
        padding-right:5px;
        @extend .font__body4;
    }
}