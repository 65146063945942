.events {
  &__container {
    margin-bottom: 40px;
  }

  &__header {
    @extend .font__h2;
    margin-bottom: 30px;
  }

  &__description {
    @extend .font__body;
    margin-bottom: 20px;
  }

  &__event {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    padding: 30px 0;

    &:not(:first-child) {
      padding-top: $mobile-vertical-padding;
      padding-bottom: $mobile-vertical-padding;
      border-top: solid 1px $colors__lines;
    }

    .section--component & {
      &:last-child {
        padding-bottom: 0;
      }
    }
    &__date {
      @extend .font__body2;
      @extend .font__body2--semibold;
    }
    &__cta {
      @extend .font__body;
      @extend .font__body--semibold;
      //display: flex;
      //align-items: center;
    }

    &__details {
	  margin-bottom: $mobile-vertical-padding;
	  
	  &--single-event {
		width: 100%;
	  }
	  
      &__date {
        @extend .font__body2;
        @extend .font__body2--semibold;
        //margin-bottom: 5px;
      }

      &__location {
        @extend .font__body;
        font-size: 18px;
      }
    }

    &__hosted {
      @extend .font__subhead2;
      color: $colors__blue--dark;
    }

    &__description {
      margin-bottom: 30px;
      @extend .font__body;
    }

    &__header {
      @extend .font__h2;
      //@extend .font__h2--extrasmall;
      margin-bottom: 30px;
    }
  }

  @include above(mobile) {
    &__event {
      & &__header {
        //font-size: 20px;
      }
    }

  }

  @include above(tablet) {
    &__event {

      flex-direction: row-reverse;

      &__description {
        flex-grow: 1;
        margin: 0px 11%;
      }
      &__details {
        width: 165px;
        flex-shrink: 0;
        overflow-wrap: break-word;
		margin-bottom: 0;
		
		&--single-event {
		  width: 100%;
		  margin-bottom: $mobile-vertical-padding;
		}
      }
      &__header {
        width: 33%;
      }
    }
    & &__event {
      padding-top: $desktop-vertical-padding;
      padding-bottom: 40px;
    }
  }

  @include above(desktop) {
    &__container {
      //margin-bottom: $desktop-vertical-padding;
    }
    
  }
}

.events-no-header {
  //border-top: solid 1px $colors__lines;
}

.events-landing {
  &__rich-text {
    margin-bottom:0px;
  }
}