.twitter-timeline-component {
  @include above(mobile) {
    &__left{
      &.component-container__body__left {
        flex-basis:40%;
      }
    }
    &__right{
      &.component-container__body__right {
        flex-basis:60%;
      }
    }
  }
}