.diversity {
  &__header {
   
  
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__subheader {
    width:75%;
  }
  @include above(tablet) {
    &__container {
      flex-direction: row;
    }
  }
}