.footer {
  background-color: $colors__navy;
  color: $colors__white;
  padding: 30px 0;
  z-index: 2;
  position:relative;
  font-variant-ligatures: no-common-ligatures;

  &__container {
    @include container();
  }

  &__row {
    &:not(:first-child) {
      margin-top: 28px;
    }
  }

  &__title {
    @extend .font__subhead2;
  }

  &__faded {
    opacity: .5;
    @extend .font__body2;
    @extend .font__body2--extralight;
  }

  &__link {
    @include transition(color, opacity $hover-speed ease-in-out);
    &:hover {
      opacity: 1;
      color: $colors__white !important;
    }
  }

  .footer__link, .link--body4 {
    @include transition(color, opacity $hover-speed ease-in-out);
    opacity: 0.5;
    font-size: 14px;

    &:hover {      
      opacity: 1 !important;
      color: $colors__white !important;
    }
  }

  .link--body4 {
    font-size: 12px;
  }

  &__links {
    display: flex;
  }
  &__link-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__caption {
    @extend .font__body2;
    margin-top: 8px;
   
  }
  &__form {
    margin-top: 8px;    
    .searchbar__right {
      margin-left: 0;
    }
    &-input {
      margin-top: 10px;
    }
    .searchbar__placeholder {
      left: 14px;
      @extend .font__body4;
    }
  }
  &__social-links {
    display: flex;
    margin-top: 16px;
  }
  &__social-link {
    @include fadeOnHover();
    &:not(:first-child) {
      margin-left: 18px;
    }
  }
  &__divider-container {
    @include container();
  }
  &__divider {
    height: 2px;
    background-color: $colors__black;
    margin-top: 40px;
    margin-bottom: $mobile-vertical-padding;
    opacity: .3;
  }
  &__copyright {
    @extend .font__body4;
    @extend .font__body4--extralight;
    margin-top: 24px;
  }
  &__desktop {
    display: none;
  }
  &__split-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__split-link {
    margin-top: 5px;
  }
  @include above(tablet) {
    &__desktop {
      display: flex;
    }
    &__mobile {
      display: none;
    }
    &__top {
      display: flex;
    }
    &__row {
      &:not(:first-child) {
        margin-top: 12px;
      }
      &--flex {
        flex: 1;
        &:not(:first-child) {
          padding-left: 120px;
        }
      }
    }
    &__title {
      margin-bottom: 12px;
    }
    &__divider-container {
      padding: 0;
      max-width: 100vw;
    }
    &__split-link-group {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      flex: 1;
    }
    &__split-links {
      flex-direction: row;
    }
    &__split-link {
      margin-top: 0;
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
    &__bottom {
      display: flex;
      align-items: center;
    }
    &__social-links {
      margin: 0;
      margin-top: 1rem;      
    }
  }
}

