.insight-listing {
    @extend .font__body2;
    color: $colors__black;
    margin-top: 1.7em;

    &__title {
        color: $colors__blue--dark;
        @include links__hover-black();
        @extend .font__body2--semibold;
    }   

    &__link {
        margin-bottom: 5px;
        
    }

   
}

span.insight-listing__title {
    color: $colors__blue--dark;
    @extend .font__body2;
    @include links__hover-black();
    @extend .font__body2--semibold;
}