.map {
  background: $colors__coral;
  path {
    fill: $colors__coral;
    stroke: rgba(0,0,0,.2);
    stroke-width: 1;
    transition: .5s all linear;
    &:hover {
      fill: $colors__coral;
      transition: 0s all linear;
      cursor: pointer;
    }
  }
}
 
