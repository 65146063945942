

// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
    $unfoldedTransitions: ();
    @each $transition in $transitions {
      $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
    }
  
    transition: $unfoldedTransitions; 
  }
  
  @function unfoldTransition ($transition) {
    // Default values
    $property: all;
    $duration: $transition__timing;
    $easing: $transition__ease; // Browser default is ease, which is what we want
    $delay: null; // Browser default is 0, which is what we want
    $defaultProperties: ($property, $duration, $easing, $delay);
  
    // Grab transition properties if they exist
    $unfoldedTransition: ();
    @for $i from 1 through length($defaultProperties) {
      $p: null;
      @if $i <= length($transition) {
        $p: nth($transition, $i)
      } @else {
        $p: nth($defaultProperties, $i)
      }
      $unfoldedTransition: append($unfoldedTransition, $p);
    }
  
    @return $unfoldedTransition;
  }
  
  // Usage:   @include transition(width, height 0.3s ease-in-out);
  // Output:  transition(width 0.2s, height 0.3s ease-in-out);
  //
  // Pass in any number of transitions
  @mixin transition-bezier($transitions...) {
    $unfoldedTransitions: ();
    @each $transition in $transitions {
      $unfoldedTransitions: append($unfoldedTransitions, unfoldTransitionBezier($transition), comma);
    }
  
    transition: $unfoldedTransitions; 
  }
  
  @function unfoldTransitionBezier ($transition) {
    // Default values
    $property: all;
    $duration: $transition__timing;
    $easing: $transition__bezier; // Browser default is ease, which is what we want
    $delay: null; // Browser default is 0, which is what we want
    $defaultProperties: ($property, $duration, $easing, $delay);
  
    // Grab transition properties if they exist
    $unfoldedTransition: ();
    @for $i from 1 through length($defaultProperties) {
      $p: null;
      @if $i <= length($transition) {
        $p: nth($transition, $i)
      } @else {
        $p: nth($defaultProperties, $i)
      }
      $unfoldedTransition: append($unfoldedTransition, $p);
    }
  
    @return $unfoldedTransition;
  }