.carousel {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: $colors__white;
  color: $colors__navy;
  flex: 1 1 0px;
  .cta-component-image {    
    @include above(desktop) { 
      height: 30rem;      
    }

    @include below(desktop) { 
      .cta-component-image__text:not(.carousel__controls) {
        min-height: auto;
      }
    }    
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__arrows {
    position: absolute;
    left: 50%;
    top: 0px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    padding: 5px;
    transform: translateX(-50%);
    pointer-events: none;
  }
  
  &__arrow {
    width: 35px;
    height: 35px;
    border: 1px solid $colors__gray;
    color: $colors__gray;
    background-color: #FFF;
    font-size: 14px;
    border-radius: 50%;
    transition: all .3s ease-out;
    text-align: center;
    cursor: pointer;
    opacity: .9;
    pointer-events: all;
    &:focus {outline:0;}
  }
  &__left {
    padding-left: 60px;
  }

  &__right-arrow {
   
    z-index:5;

    &--grey {
      color:black;
     
    }
  }
  &__left-arrow {
   
    z-index:5;
    &--grey {
      color:black;
     
    }
  }
  &__slides-wrapper {
    width: calc(100vw);
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  &__slides {
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;
    flex: 1 0 auto;
    transition: transform 300ms ease-in-out;
  }
  &__slide {
    min-width: 100%;    
    justify-content: center;
    display: flex;
    @include above(desktop) { 
      height: 500px;
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: $colors__navy;
    position: absolute;
    z-index: 2;
    margin: 0 auto;
    max-width: 90rem;
    bottom:0px;
    left: 50%;
    color:white;
    transform: translateX(-50%);
    pointer-events: none;
    &--image-left {
      flex-direction: row-reverse;
    }
  }
  &__left {
    transform: rotate(180deg);
  }
  &__dots {
    display: flex;
    &--half {
      margin-left: auto;
    }
    &--dark {
      .carousel__dot {
        color: black;
        opacity: .5;
        &--active {
          opacity:1;      
        }
      }    
    }
  }
  &__dot {
    color: $colors__white;    
    font-size:12px;
    opacity:.7;
    pointer-events: all;
    &:focus {
      outline:none;
    }
    &:not(:first-child) {
      margin-left: .5rem;
    }
    &--active {
      opacity:1;      
    }
  }
  @include above(tablet) {
    &__dots {
      margin-left: 0px;
    }
    
    &__arrows {
      padding: 15px;
    }
    
    &__arrow {
	    &--light{
		    color: $colors__white;
      }
      &:hover{
        color: $colors__white;
        background-color: $colors__gray;
      }
    }


  
  }
}

body {
  overflow-x: hidden;
}
