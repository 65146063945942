.link-list-component {
  width:100%;
  
  &__left {
    padding-right:25px;

    @include above(tablet) {
      &--extra-spacing {
        padding-right:75px;
      }
    }
  }

  &__list {
    &__link {
      @extend .font__body--semibold;
      margin-bottom: 8px;
      @include transition(color);

      a {
        @extend .font__body;
        @extend .font__body--semibold;

        &:hover {
          @include transition(color);
          color: $colors__coral;
        }
      }
    }   
  } 
}