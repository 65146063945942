.component-card {
    @extend .font__body3;
    margin-bottom: $desktop-vertical-padding;
    //flex-grow: 1;

    &__bar {
        height: 4px;
        width: 100%;
        background: $colors__gray;
        margin-bottom: 20px;

        @include themify($themes) {
            background: themed('color');
        }
    }

    &__icon {
        height: 0;
        width: 100%;
        padding-top: 67%;
        //background-size: 180px;
        background-size: 300px;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 20px;

        &--cover {
            background-size: cover;
        }

        @include themify($themes) {
            background-color: themed('color');
        }        
    }
    &__image {
        height: 0;
        width: 100%;
        padding-top: 67%;
        //background-size: 100% 100%;
        background-size: cover;
        background-position: center;
        margin-bottom: 20px;
    }
    &__eyebrow {
        @extend .font__body3;
        text-transform: none;
    }
    &__type {
        @extend .font__subhead2;
        margin-top: 2px;
        // color: $colors__coral;
        // give it a color css class 
        //margin-bottom: 10px;
        text-transform: uppercase;
        &--title-case {
            text-transform: none;
        }
        @include themify($themes) {
            color: themed('color');
        }        
    }
    &__type + &__category {
        
        margin-bottom: 0px;
    }

    &__category {}

    &__title {
        //margin-top: 20px;
        @extend .font__body;
        @extend .font__body--semibold;
        padding: .5em 0;
        @include transition(transform);
        display: block;
    }

    &__more {
        //display: block;
        margin-top: 20px;
    }

    &__date {
        @extend .font__subhead2;
        //margin-top: 20px;
    }
    //component-card-container modifies the sizing to keep the card styling independent of grid sizing

    @include above(tablet) {
        padding: 20px;
        //padding-left:0px;
    }
}
