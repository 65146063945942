#onetrust-consent-sdk {

    #onetrust-banner-sdk {
        overflow-y: hidden;
    }

    .cookie-setting-link {
        color: $colors__coral !important;
    }
}

.homepage-inquiry__footer, 
footer.footer {
    button.ot-sdk-show-settings {
        border: none !important;
        padding: 0 !important;
        opacity: .5 !important;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 1.375rem !important;
        color: $colors__white !important;
        padding-bottom: 1px !important;
        //border-bottom: .5px solid $colors__white !important;
        transition: color,opacity,border-color $hover-speed ease-in-out !important;
        display: inline;        

        &:hover {
            background-color: transparent !important;
            opacity: 1 !important;
        }
    }
}

.rich-text {
    .ot-sdk-show-settings {
        border: none !important;
        padding: 0 !important;
        color: $colors__black !important;
        font-size: 21px !important;
        transition: color $hover-speed ease-in-out, border-bottom-color $hover-speed ease-in-out !important;
        border-bottom: solid 1px$colors__black !important;

        &:hover, &:focus {
            background-color: transparent !important;
            color: $colors__coral--dark !important;
            border-bottom-color: $colors__coral--dark !important;
        }
    }
}