.bio-hero {
  // $image-height: 513px;
  background-color: $colors__navy;
  color: $colors__white;
  position: relative;

  //TODO: transparent images are not happening, needs to be incorporated into image and removed
  // @mixin dopeGradient () {
  //   $gradient-inner: #3c484c;
  //   $gradient-outer: $colors__navy;
  //   background-color: $gradient-inner;
  //   background: radial-gradient(circle at 75%, $gradient-inner, $gradient-outer);
  // }

  &__image {
    display: none;
    background-repeat: no-repeat;
    position: relative;
  }

  &__mobile-image {
    background-repeat: no-repeat;
    background-size: 129%;
    background-position-x: center;

    //always 100% width; 
    //and square-ish 

    //one option: but can't limit to 50% screen height this way
    width: 100%;
    height: 0;
    padding-top: 69%;

    // width: 100%;
    // height: 100vw; //square ... or almost exactly
    // max-height: 50vh; //no max height - they want it to grow 
  }

  &__top-right {
    // @include dopeGradient();
  }

  &__left-bottom {
    @include container();
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &__section {
    margin-top: 32px;
  }

  &__name {
    @extend .font__h2;    
      display: inline-block;
      &--audio{
        margin-right: 22px;
        display: inline;
      }    
  }

  &__level {
    @extend .font__subhead2;
  }

  &__titles {
    padding-top: 8px;
  }

  &__title {
    @extend .font__body2;
    opacity: .5;
    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  &__contact-item {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }

  &__contact {
    @include fadeOnHover();
  }

  &__prefix {
    @extend .font__body2;
    @extend .font__body2--semibold;
    padding-right: .5em;
  }

  &__primary {
    @extend .font__body2;
  }

  &__secondary {
    @extend .font__body2;
    opacity: .5;
    padding-left: .5em;
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
  &__pronoun {
    @extend .font__body2;
    opacity: .5;
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }

  &__social-links {
    margin-top: 20px;
  }

  &__social-link {
    @include fadeOnHover();
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &__quote {
    @extend .font__body;
  }

  &__image-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  &__audio {    
      background: url("/assets/public/images/audio-white.svg") no-repeat;     
      background-size: 1rem 1rem; 
      width: 1rem;
      height: 1rem;  
      display: inline-block;
      margin-left: 2px;

      
  }

  @include above(tablet) {
    // @include dopeGradient();
    &__mobile-image {
      display: none;
    }

    &__image {
      background-size: auto 100%;
      // background-size: auto 100%; // 

      display: block;
      position: absolute;
      top: 0;
      height: 100%;

      left: 0;
      right: 0;
      margin: 0 auto;
      background-position: right 19% center;
    }

    &__container {
      @include container();
      display: flex;
      flex-direction: row-reverse;
      align-items: stretch;
      position: relative;
    }
    &__left-bottom {
      padding: 32px 0;
    }
    &__top-right {
      min-width: 45%;
      background: rgba(0, 0, 0, 0);
      display: flex;
      align-items: flex-end;
    }
    &__image-container {
      padding-bottom: 90%;
    }
  }

  @include above(desktop) {
    padding-top:84px;
    min-height: 430px;
    &__image {
      // display: block;
      background-size: cover;
      width: 100%;
    }

    &__left-bottom {
      padding:0 0 90px 0;
    }
    &__section {
      margin-top: $mobile-vertical-padding;
    }
    &__details {
      display: flex;
    }
    &__detail {
      flex: 1;
      &:not(:first-child) {
        padding-left: $mobile-vertical-padding;
        //padding-left: $desktop-vertical-padding;
      }
    }
  }

  @media(min-width: $image-width) {
    height:38vw;
    padding-top:0;
    &__left-bottom {
      padding:0;
    }
    &__container {
      top: 50%;
      transform: translateY(-50%);
    }
    &__image {
      background-position: center;
      background-position-y: bottom;
      background-size: cover;
      width: 100%;
    }
  }

  @include above(tablet) {
    &--dark-theme {
      color: $colors__black;
      .bio-hero__audio {
        background: url("/assets/public/images/audio.svg") no-repeat;
        background-size: 1rem 1rem;             
      }
    }
  }
}