$fixedHeightMobile: 2.5rem;
$fixedHeightDesktop: $fixedHeightMobile * 2;

.global-search {
  width: 100%;
  flex-grow:1;
  &__results {
    width: 100%;
    margin-top: $fixedHeightDesktop;
	
	&__more {
	  margin-top: $desktop-vertical-padding;
	}
    &__found {
      margin: $mobile-vertical-padding 0;
      @extend .font__body;

      &__result {

        &__bold {
          @extend .font__body--semibold;
          cursor: pointer;

          &:not(.global-search__results__found__result__bold--active) {
            color: $colors__black;
            border-bottom: 2px solid $colors__black;
            padding-bottom: 1px;

          }

          &:not(.global-search__results__found__result__bold):hover {
            color: $colors__black;
            border-bottom: 2px solid $colors__black;
            padding-bottom: 1px;
          }

          &--people,
          &--people:hover {
            color: $colors__teal;
            border-bottom: 2px solid $colors__teal;
            padding-bottom: 1px;
          }

          &--insights,
          &--insights:hover {
            color: $colors__blue;
            border-bottom: 2px solid $colors__blue;
            padding-bottom: 1px;
          }

          &--expertise,
          &--expertise:hover,
          &--services,
          &--services:hover {
            color: $colors__coral;
            border-bottom: 2px solid $colors__coral;
            padding-bottom: 1px;
          }

          &--other, &--other:hover {
            color: $colors__cyan--dark;
            border-bottom: 2px solid $colors__cyan--dark;
            padding-bottom: 1px;
          }
        }
      }

      &--categories {
        display: inline;
  
        .global-search__results__found__result {
          &+ .global-search__results__found__result {
            
    
            &:last-child {
              &:before {                 
                margin: 0 .333rem;
              }
            } 
          }  
        }
      }
    }
  }  

  &__fixed {
    position: fixed;
    background-color: $colors__white;
    width: 100%;
    top: $navbar-height;
    padding-top: $fixedHeightMobile;
  }

  &__search {
    position: relative;
    width: 100%;
    @extend .font__h2;
    @extend .font__h2--extralight;
    color: $colors__teal;
    @extend .icon;
    @extend .icon__search;
    display: flex;
    align-items: center;
    padding-bottom: 4px;

    &:before {
      font-size: 30px;
    }

    &:after {
      content: '';
      width: calc(100% - 5rem);
      height: 1px;
      background-color: $colors__lines;
      position: absolute;
      bottom: 0px;
      left: 2.5rem;
    }

    //margin-bottom: $mobile-vertical-padding;
    &__clear {
      font-size: 30px;
      cursor: pointer;
      color: $colors__teal;
      @extend .font__h2;
      @extend .font__h2--extralight;
      font-weight: 200;
    }

    &__input {
      margin-left: 15px;
      width: 100%;

      &::placeholder {
        color: $colors__teal;
      }
    }

    &:focus-within {
      &:after {
        background-color: $colors__teal;
      }
      
    }
  }

  @include above(tablet) {

    &__fixed {
      padding-top: $fixedHeightDesktop;
    }

    &__found {
      //margin: $desktop-vertical-padding 0;
    }
  }
}

.global-search-home {
  display: flex;
  flex-direction: column;
  height: 100%;

  .container {
    flex-grow: 1;
  }
}
