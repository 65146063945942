.insight-card {
    @extend .font__body3;
    margin-bottom: $desktop-vertical-padding;
    &__bar {
        height: 4px;
        width:100%;
        background: $colors__gray;
        margin-bottom: 30px;
        &--Publication {
            background: $colors__teal;
        }
        &--Blog {
            background: $colors__coral;
        }
        &--Advisory {
            background: $colors__gray;
        }
        &--News {
            background: $colors__cyan;
        }
        &--Presentations, &--news {
            background: $colors__blue;
        }
    }
    &__image {
        height: 0;
        width: 100%;
        padding-top: 67%;
        background-size: cover;
        background-position: center;
        margin-bottom: $mobile-vertical-padding;
    }

    &__type {
        @extend .font__subhead2;
        color: $colors__gray;
    }

    &__category {

    }

    &__title {
        margin-top: 20px;
        @extend .font__body;
        @extend .font__body--semibold;
        padding: .3em 0;
        @include transition(transform);
        display: block;
    }   

    &__more {
        //display: block;
        margin-top: 20px;
    }

    &__date {
        @extend .font__subhead2;
    }
    //insight-card-container modifies the sizing to keep the card styling independent of grid sizing

    @include above(tablet) {
        padding: 5px;
        //padding-left:0px;
    }

    &.theme-medium-blue {
       .insight-card__type {
           color: $colors__blue;
       }
    }
    &.theme-coral {
        .insight-card__type {
            color: $colors__coral;
        }
    }
    &.theme-cyan {
        .insight-card__type {
            color: $colors__cyan;
        }
    }
    &.theme-teal {
        .insight-card__type {
            color: $colors__teal;
        }
    }
    &.theme-yellow {
        .insight-card__type {
            color: $colors__yellow;
        }
    }
}