.minimal-dropdown {
  // height: 1.5em;
  border-bottom: 1px solid;
  border-color: inherit;
  width: 100%;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  //space for icon
  padding-right: 1em;

  &::after {
    @include font-family-icomoon();
    content: $icon__chevron-thin-down;
    @include transition(transform);
    font-size: .8em; //was weirdly big 

    position: absolute;
    right: 0;
    top: 0.2em;
  }

  &--no-arrow {
    &::after {
      visibility: hidden;
    }
  }

  &--up-arrow {

    &::after {
      transform: rotate(-180deg);
    }
  }


  &__options {
    padding-top: .3em;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width:100%;
    &.active {
      visibility: visible;
      animation: .5s minimalDropdownFadeIn;
    }
  }

  &__option {
    width:100%;
    margin-top: .3em;
    display: block;
    @include links__hover-yellow;
  }

  &__placeholder {
    //prevent text / element height from collapsing when there is no text in it
  }
}