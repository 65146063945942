.navbar {

  $spacing-x: 20px;
  $spacing-y: 20px;
  $logo-width: 121px;

  background-color: $colors__white;
  box-sizing: border-box;
  padding: $spacing-y 0;

  padding-top: 0;

  &--fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    transition: top 1s;
  }

  &--home {
    position: relative;
  }

  &__hidden{
    display: none;
  }

  &--hide {
    top: -66px;
    transition: top 1s;
  }

  &--fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 5;

    //for homepage, mostly
    box-shadow: 0px 0px 16px -3px $colors__black;
  }

  &__page {
    padding-top: $navbar-height;
  }

  &__page-bottom {
    height: 100%;
    top: auto;
    height: calc(100% - 71px);

    &--active{
      //padding-top: $navbar-height;
      //padding-top: 71px;      
    }    
  }

  &__container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    padding-top: 1.25rem;
  }

  // For centering center links
  &__left,
  &__right {
    flex: 1;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  &__brand {
    width: $logo-width;
    @include fadeOnHover();    

    img {
      width: 100%;
      transform: scale(1.4);
      margin-left: 29px;
    }
  }

  &__links {
    display: none;
    position: relative;
    margin-right: 50px;
  }

  &__link {
    &:hover {
      color: $colors__blue--dark;
    }

    margin-left: $link-spacing;

    &--primary {
      @extend .font__body2;
      @extend .font__body2--semibold;

      &:first-child {
        margin-left: 0;
      }
    }

    &--secondary {
      @extend .font__body3;
    }

    &--active {
      color: $colors__blue--dark;
    }
  }

  &__icon {
    font-size: 18px;

    &__search {
      position: relative;
      z-index: 9;
    }
  }

  &__menu {
    @include fadeOnHover();
  }

  @include above(desktop) {
    &__menu {
      display: none;
    }

    &__links {
      display: flex;
      align-items: center;
    }
  }
}

.navbar-search {
  display: none;
  z-index: 8;
  width: 25px;
  position: absolute;
  right: 0px;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
  transition: width .35s cubic-bezier(.785, .135, .15, .86) .4s;
  &__container {
    position: relative;
    display: flex;
    background: $colors__white;
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 0px;
      bottom: 0px;
      left: 0px;
      background-color: $colors__teal;
      transition: width .35s cubic-bezier(.785, .135, .15, .86) .35s;
    }
    .navbar__link--secondary{
      flex-shrink: 0;
    }
  }

  .searchbar__input, .icon__close {
    // visibility: hidden;
  }

  &--open {
    width: 300px;

    .searchbar__input, .icon__close {
      visibility: visible;
    }

    .navbar__icon__search {
      color: $colors__teal;
    }
    .navbar-search__container {
      &:after {
        width: 100%;
      }
    }
  }
  @include above(desktop) {
    display: block;
  }
}

header.navbar {
  height: auto;
}