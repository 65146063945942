.about {
  display: flex;
  flex-direction: column-reverse;

  .section__aside {
    border-bottom: 0px;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 0px;
    border-top: solid 0.0625rem #b4b4b4;
    margin-bottom: 0px;
  }
  @include above(tablet) {
    flex-direction: row-reverse;
    .section__aside { 
      border-top: 0px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }
}