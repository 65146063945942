.style-guide {
  &__section {
    margin: $desktop-vertical-padding 0;
    &-title {
      @extend .font__subhead1;
      margin-bottom: 1rem;
      border-bottom: solid 1px $colors__lines;
    }
    &-region {
      margin-top: 24px;
    }
  }
}