/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {    
    .vc-container {
        width: 258px;
        height: 269px;
    
        .vc-w-full {
            width: 100%;
            height: 100%;
        }
    
        .vc-grid-container {
            height: 100%;
            display: -ms-grid !important;
            display: grid;
            -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
              grid-template-columns:  repeat( 4, 1fr );
            -ms-grid-rows: auto;
              grid-template-rows: repeat( 4, auto );
            -ms-grid-gap: 30px;
              grid-gap: 30px;        
    
            .vc-nav-container {
                .vc-grid-container {
                    -ms-grid-columns: 1fr 1fr 1fr;
                }
            }
    
            .vc-nav-container[data-v-d8853504] {
                .vc-grid-container[data-v-29dcba24] {
                    &:first-child {
                        display: flex !important;
                        flex-direction: row;
                        justify-content: space-between;

                        .vc-grid-cell-col--2 {
                            margin-top: 5px;
                        }
                    }                    
                }
            }            

            .vc-grid-cell.vc-grid-cell-row-1.vc-grid-cell-row--1.vc-grid-cell-col-1.vc-grid-cell-col--1 {
                min-height: 266px;
                min-width: 255px;
    
                .vc-header {
                    position: relative;
                    top: 0;
                    width: 100%;
                    text-align: center;
                }
    
                .vc-grid-container {
                    // margin-top: 20px;
    
                    .vc-grid-cell {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .vc-popover-caret.direction-bottom.align-left {
            display: none;
        }
    }

    span.vc-popover-caret.direction-bottom.align-left {
        display: none;
    }

    .regular-input__datepicker {
        .vc-popover-content-wrapper {
            margin-top: 10px;
        }
    }

    .vc-grid-container {
        overflow: hidden;
    }

    .vc-popover-content-wrapper {
        overflow: hidden;
        -ms-overflow-style: none;
    }

    .vc-bg-gray-800 {
        width: 164px!important;
    }

    .vc-grid-cell {
        display: block!important;
    }

    .vc-highlight {
        width: 28px!important;
        height: 28px!important;
        margin: 0!important;
    }

    .vc-day {
        width: 35px!important;
    }


    .vc-day-content {
        width: 28px!important;
        height: 28px!important;
        &:hover {
            background-color: rgba(204,214,224,0.3)!important;
        }
    }
}