.twitter {
  &__container {
    display:flex;
    //width:100%;
    flex-direction:column;
    flex-wrap: wrap;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    flex-grow: 1;
    &__tweet {
      width:100%;
      position:relative;
      padding:20px;
      flex-shrink:1;
      //margin: 0 auto;
      margin-bottom: 20px;
    }
    @include above(tablet) {
      flex-direction:row;
      &__tweet {
        width:50%;
      }
    }
    @include above(desktop) {
      &__tweet {
        width:33.33%;
      }
    }
  }
  

}
.Tweet-header {
  display:none;
}