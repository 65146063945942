.insights-search {
  width: 100%;  

  &__filters {
    display: flex;
  flex-direction: column;
  margin-bottom: $mobile-vertical-padding;
  }

  &__more {
    &__button {
      font-weight: 500;
    }
  }

  &__insights__no-results {
    @extend .font__body;
    @extend .font__body--semibold;
  }

  @include above(tablet) {
    &__filters {
      flex-direction: row;
      //justify-content: space-between;
    }

    &__search {
      //float: right;
    }
  }
}