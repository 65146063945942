// the entry point to the home page - main text and stuff 

$homepage-text-color: $colors__white;

@mixin homepage-hidden(){
  display: none;
  // pointer-events: none;
  // opacity: 0;
  // height: 0;
  // margin:0;
}

.homepage-inquiry {
  height: 100%;
  color: $homepage-text-color;
  position: relative;

  &--search-view {
    //hide the search text we don't want to show after clicking a search option
    .homepage-inquiry__top-text,
    .homepage-inquiry__result,
    .homepage-inquiry__search-text {
      @include homepage-hidden();
    }

    .homepage-inquiry__select-width-wrapper {
      position: static;
    }
    //decrease the font size so it all fits on one line
    .homepage-inquiry__select {
      font-size: 18px;
      //make up for smaller font size
      padding-top: 6px;
    }

    //show the extra top text, mobile only 
    .homepage-inquiry__mobile-search-text {
      display: inline-block;
    }

    //and screw with the dropdown so it shrinks to fit the extra text that needs to go next to it
    .homepage-inquiry__minimal-dropdown {
      width: auto;
      display: inline;
    }
  }

  &__intro{
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all .5s ease;
    padding-bottom: $homepage-inquiry-vertical-padding;
  }

  &__padding-wrapper {
    //had some trouble with the transition working in chrome- sometimes it would slide up to the wrong value
    //I think height: 100% fixes it
    //but if you've come here to change this, double-check
    padding: $homepage-inquiry-vertical-padding $homepage-inquiry-side-padding;
    height: 92%;
  }

  //How can we help you? 
  &__top-text{
    @extend .font__subhead3;

    &--desktop{
      display: none;
    }
  }

  //I'm looking for 
  &__search-text {
    @extend .font__h2;
    @extend .font__h2--extralight;
    // margin-top: $desktop-vertical-padding;
  }

  &__mobile-search-text {
    display: none;
    &::after {
      content: ' ';
      white-space: pre;
    }
  }

  &__select {
    &:focus {
      outline:none;
    }
    position: absolute;
    width: 100%;
    top: 50%;
    transition: top .5s ease;
    @extend .font__h2--desktop;
    @extend .font__h2--extralight;
    transition: top .3s ease, opacity .4s ease-out, width .3s ease-out, font-size .2s ease;

    &-width-wrapper {
      position: relative;
      margin-bottom: 3rem;
    }

    .homepage-inquiry--search-view & {
      top: 0;
    }
  }

  //minimal-dropdown options - smaller than placeholder text in this case
  &__option {
    @extend .font__subhead1;
    @extend .font__subhead1--extralight;
    white-space: nowrap; //don't break word when selected option is shorter than dropdown option
  }

  //position this right above the centered select
  &__top {
    position: absolute;
    bottom: 50%;
    padding-bottom: 5rem;
  }

  &__result {
    display: block;
    margin: .5em 0;
    @include links__hover-yellow();
  }

  &__footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: none;
    background-color: $colors__navy;

    &--initialized{
      display: block;
    }
    @media (max-width: 970px) {
        &--initialized {
          height: 15%;    
        }
    }
    @media (max-height: 820px) {
      &--initialized {
        height: 15%;    
      }
    }
  }

  &__footer-links{
    list-style-type: none;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    &--right{
      margin-top: 5px;
    }
  }

  &__footer-link-item{
    @extend .font__body4;
    color: $colors__white;
    margin-right: 30px;
    margin-top: 5px;

    &:last-child{
      margin-right: 0;
    }

    &__text{
      @extend .font__body4--extralight;
      opacity: 0.5;
      line-height: 1;
    }
  
    &__link {
      padding-bottom: 1px;
      border-bottom: .5px solid $colors__white;
      transition: color,opacity,border-color $hover-speed ease-in-out;
      display: inline;
      opacity: 0.5;
    }
  }

  @include above(tablet) {
    &__top-text{
      &--desktop{
        display: block;
      }
      &--mobile{
        display: none;
      }
    }
  }

  @include above(desktop) {
    &__footer{
      flex-direction: row;
    }
    &__footer-links{
      width: 50%;
      &--right{
        justify-content: flex-end;
        margin-top: 0;
      }
      
    }
    &__footer-link-item{
      &__link{
        &:hover:not(.link--active),
        &:focus:not(.link--active) {
          opacity: 1;
        }
      }
    }
    //un-hide stuff hidden on mobile 
    &--search-view {
      .homepage-inquiry__top-text,
      .homepage-inquiry__result,
      .homepage-inquiry__search-text {
        display: block;
      }
    }

    &__intro {
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      align-content: flex-end;
      flex-wrap: wrap;
      height: 38%;
      padding-right: 33%; //match side menu
    }
    @media (max-height: 820px) {
      &__intro {
        height: 50%;   
      }
    }

    &__padding-wrapper {
      padding: 0 5vw;
      height: 100%;
    }

    &__top {
      position: relative;
      bottom: auto;
      padding-bottom: 0;
      width: 100%;
      //part 1 of 2 (2nd is the dropdown)
      transform: translateX(-40px);
      opacity: 0;
      transition: transform .4s ease-out, opacity .3s ease-out;
      
      .homepage-inquiry--initial-animation & {
        opacity: 1;
        transform: none;
      }    
    }

    &__select {
      width: 10px;
      position: relative;
      top: auto;
      opacity: 0;
      // transition: opacity .4s ease-out, width .3s ease-out, font-size .4s ease;
      //transitions defined in mobile
      transition-delay: .25s;

      //stop the mobile styles from changing the font size on desktop
      font-size: 50px !important;
      line-height: 57px !important;

      .minimal-dropdown::after { 

        //shrink this carat size on desktop only 
        font-size: .6em; 
        top: .5em; 

        //don't break the dropdown open-close carat transition messing with this
        .homepage-inquiry--initial-animation & {
          //special keyframe animation that reveals at the end
          //because transition is take up by the carat flip flop
          animation: homepageArrowFadeIn .7s;
        }
      }

      .homepage-inquiry--initial-animation & {
        width: 500px;
        opacity: 1;
      }
    }

    &__mobile-search-text {
      display: none !important;
    }

    //for middle screen sizes where the dropdown breaks to the next line
    //the width needs to be kept through the animation so it doesn't 
    //start on the same line and then break once it gets wide enough
    &__select-width-wrapper {
      width: 500px;
      margin-bottom: 0rem;
      margin-top: 3rem;
    }

    &__search-text {
      //space between "I'm looking for" and "____"
      margin-right: 18px;
      transform: translateX(-40px);
      opacity: 0;
      transition: transform .4s ease-out, opacity .3s ease-out;
      
      .homepage-inquiry--initial-animation & {
        opacity: 1;
        transform: none;
      }
    }
  }

  @media (max-height: 750px) and (max-width: 480px) {
    &__padding-wrapper {
      overflow: auto;      
    }
  }
  @include below(mobile) {
    //&__footer-links {
      //justify-content: space-between;
    //}
    &__footer-link-item {      
      margin-right: 15px;      
  
      &:last-child{
        margin-right: 0;
      }
    }
  }
}