.color {
  &__navy {
    color: $colors__navy;
  }
  &__navy--dark{
    color: $colors__navy--dark;
  }

  &__blue {
    color: $colors__blue;
  }
  &__blue--dark {
    color: $colors__blue--dark;
  }

  &__cyan {
    color: $colors__cyan;
  }
  &__cyan--dark {
    color: $colors__cyan--dark;
  }

  &__teal {
    color: $colors__teal;
  }
  &__teal-dark {
    color: $colors__teal--dark;
  }

  &__coral {
    color: $colors__coral;
  }
  &__coral--dark{
    color: $colors__coral--dark
  }

  &__yellow {
    color: $colors__yellow;
  }
  &__yellow--dark {
    color: $colors__yellow--dark;
  }

  &__gray {
    color: $colors__gray;
  }
  &__gray--dark {
    color: $colors__gray--dark;
  }  
}