.related-articles {
  .insight-card {
    flex-grow: 1; 
  }
  &__header {
    @extend .font__h2;
    margin-bottom:30px;
  }
  &-container {
    flex-wrap: nowrap;
  }
}