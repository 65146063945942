.practice {
  &__tabs {
    margin-left: auto;
    margin-right: auto;
  }

  .insight-card {
    //flex-grow: 1;
  }

  .container {
    //padding:0;
  }

  .section__main {
    @include container();
    //margin-left: 1.25rem;
    //padding-left:0;
    width: 100%;
  }
}

.card-component {
  &__header {
    @extend .font__h2;
    margin-bottom: $mobile-vertical-padding;
  }
}





