.component {
  &-container {
    &__header {
      @extend .font__h2;
      //margin-top: -15px;
      margin-bottom: $mobile-vertical-padding;
	  
    }
    &__subheader-container {
	  display: flex;
      flex-direction: column;
      justify-content: space-between;
	}
    &__subheader {
      @extend .font__body;
      margin-bottom: $mobile-vertical-padding;
	  width: 100%;
	  
	  &--with-nav {
	    width:75%;
	  }
	  
	  p {
		@extend .font__body;
	  }
    }
    &__body {
      display:flex;
      @extend .font__body;
      flex-direction: column;
      align-content: space-between;
     &__left {
      &__text {
        margin-bottom: $mobile-vertical-padding;
      }
     }
    }
    
  }
  @include above(mobile) {
    &-container {
      &__body {
        flex-direction: row;
        &__left {
          flex-basis:50%;
        }
        &__right {
          flex-basis:50%;
        }
      }
    }
    
  }
  @include above(tablet) {
  &-container {
    &__subheader-container {
      flex-direction: row;
    }
	}
  }
  
  @include above(desktop) {
    &__header {
      margin-bottom: $desktop-vertical-padding;
    }

    &__subheader {
      max-width: 700px;
      margin-bottom: $desktop-vertical-padding;
    }
  }
}