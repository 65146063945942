.service-listing {
    @extend .font__body2;
    color: $colors__black;
    margin-top: 1.7em;

    &__name {
        color: $colors__coral--dark;
        @include links__hover-black();
        @extend .font__body2--semibold;
    }   

    &__link {
        //margin-bottom: 5px;
    }

    &__title {
        @extend .font__body2;
    }

    &__sub {
        @extend .font__body4;
    }

    &__sub-line {
        margin-bottom: 5px;
    }

    &__description {
        
    }
}