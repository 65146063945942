.share-links {
 
  &__links {
    margin: 9px 0px;
    display: flex;
    padding-left: 16px;
    &__link {
      margin-right: 8px;
    }
  }
}