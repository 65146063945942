.rich-text--smaller {
    @extend .font__body2;
}

.rich-text {
    @extend .font__body;
}
.rich-text,
.rich-text--smaller {
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    blockquote,
    p {
        &:not(:first-child) {
            margin-top: $mobile-vertical-padding;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    h3,
    h4,
    h5,
    h6 {
        & + p,
        & + ul {
            &:not(:first-child) {
                margin-top: 0;
            }
        }
    }
    ul,
    ol {
        &:not(:first-child) {
            ul,ol {
                margin-top: 0;
            }
        }
    }
    blockquote {
        @extend blockquote;
    }

    &__abstract {
        margin-bottom: $mobile-vertical-padding;
    }

    img,
    iframe {
        max-width: 100%;
        height: auto;
    }

    iframe {
        min-height: 200px;
    }

    &--hide-img {
        img {
            display: none;

            &.force-show-img {
                display: block;
            }
        }
    }

    br {
        line-height: 10px;
    }

    h2 {
        @extend .font__h2;
    }

    h3 {
        @extend .font__subhead1;
        font-size: 1.8rem;
        line-height: 2.6rem;
    }

    h4 {
        @extend .font__body;
        font-weight: $fonts__semibold;
    }

    h5 {
        @extend .font__body2;
        font-weight: $fonts__semibold;
    }

    h6 {
        @extend .font__body3;
        font-weight: $fonts__semibold;
    }

    p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
    }

    a {
        position: relative;
        //white-space: nowrap;
        transition: color $hover-speed ease-in-out;
        border-bottom: solid 1px;
        padding-bottom: 1px;
        display: inline;

        &:hover:not(.link--active),
        &:focus:not(.link--active) {
            color: $colors__coral--dark;
        }

        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;

        &.remove-link-style {
            color: inherit;
            border: none;

            &:hover:not(.link--active),
            &:focus:not(.link--active) {
                color: inherit;
            }
        }

        &.button,
        .button {
            @extend .button;
            cursor: pointer;
        }
    }

    .caption-text {
        @extend .font__body4;
    }

    .semi-bold {
        font-weight: $fonts__regular;
    }

    .button {
        @extend .button;
        display: inline-block;
        margin-bottom: .5px;

        a {
            border: none;

            &:hover:not(.link--active),
            &:focus:not(.link--active) {
                color: inherit;
            }
        }

        &:hover {
            padding: 12px 24px;
            padding-bottom: 11px;
        }
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }

    ol,
    ul {
        padding-left: 40px;
        margin-right: 20px;
        
        a {
            vertical-align: top;
        }
    }

    &.rich-text__list-padding {
        ul {
            margin-top: 10px;
        }
    }

    strong {
        font-weight: $fonts__semibold;
    }

    &__abstract + & {
        margin-top: 24px;
    }

    &--blue,
    & &--blue {
        color: $colors__blue--dark;
        @extend .font__subhead1;
        font-size: 26px;
        font-weight: 200;
        line-height: 38px;
        margin-bottom: 24px;

        p {
            font-size: 26px;
            font-weight: 200;
            line-height: 38px;
        }

        a {
            font-size: 26px;
        }

        strong {
            font-weight: $fonts__regular;
        }
    }

    &__responsive-table,
    #table {
        margin: 2em 0;
        width: 94vw;
        overflow: auto;
        //overflow-x:scroll;
        &::-webkit-scrollbar {
            display: none;
        }

        &.responsive-table--small {
            width: 94vw;

            table {
                width: 600px;
            }
        }
    }

    table {
        width: 1280px;
        margin-top: 2em;
        //width:130%;
        overflow: auto;
        vertical-align: top;
        border-collapse: collapse;

        a {
            margin-right: 5%;
        }

        tr {
            border-top: 1px solid $colors__lines;
            border-bottom: 1px solid $colors__lines;
            margin-bottom: 20px;
        }

        th,
        td {
            padding: 1rem .5rem 1rem 0;
        }

        th {
            @extend .font__subhead2;
            text-align: left;
            //max-width: 73px;
        }

        td {
            max-width: 130px;
            @extend .font__body2;
            vertical-align: top;
            padding-bottom: 1rem;
        }
    }

    p {
        &.rich-text__component-border:not(:first-child) {
            border-top: solid 1px $colors__lines;
            margin: 32px 0;
            height: 1px;
        }
    }

    &__image-caption,
    &__video-caption {
        display: flex;
        vertical-align: top;
        flex-direction: column;

        iframe,
        img {
            max-width: 100%;
        }

        span {
            @extend .font__body4;
            margin-top: 15px;
            padding: 5px;
        }
    }

    &__video-caption {
        overflow: hidden;
        position: relative;

        iframe {
            display: block;
            width: 560px;
            height: 315px;
        }

        span {
            border: none;
        }
    }

    @include above(mobile) {
        &__responsive-table,
        #table {
            &.responsive-table--small {
                width: 100%;

                table {
                    width: 100%;
                }
            }
        }
    }

    @include above(tablet) {
        iframe {
            min-height: 250px;
        }

        table {
            th,
            td {
                padding: 2.25rem 1.25rem 2rem 0;
            }

            td {
                padding-bottom: 3rem;
            }
        }

        &__video-caption,
        &__image-caption {
            flex-direction: row;
            //justify-content: center;
            span {
                width: 27%;
                margin-left: 20px;
                margin-top: 0px;
                padding: 0px;
            }
        }

        p {
            &.rich-text__component-border:not(:first-child) {
                margin: 72px 0;
            }
        }
    }

    @include above(desktop) {
        iframe {
            min-height: 360px;
        }

        &__abstract + & {
            margin-top: 46px;
        }

        &--blue,
        & &--blue {
            color: $colors__blue--dark;
            margin-bottom: 46px;
        }
    }
}

.rich-text {
    h2.h2-style {
        font-size: 2.25rem;
        line-height: 2.9rem;
    }
    &--list-spacing {
        a {
            color: $colors__coral;
        }
        ul {
            li {
                margin-top: $mobile-vertical-padding-custom;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}