.subscribe {
  width: 100%;
  &__container {
    display: flex;
    flex-direction: column-reverse;
  }
 
  &__header {
    @extend .font__h2;
  }
  &__description {
    margin-top: $mobile-vertical-padding;
    @extend .font__body;
  }
  
  &__form {
    margin-top: $mobile-vertical-padding;
    
    &__result-message {
      margin-top: $mobile-vertical-padding;
      @extend .font__body;
      &.rich-text{
        h2{
          @extend .font__body;
        }
      }
    }
    
    &__topics {
      margin-top: $mobile-vertical-padding;

      &__expand-container{
        cursor: pointer;
        width: 100%;
      }

      &__add {
        @extend .font__body;
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
        display: flex;
        font-size: 18px;
      }

      &__collection {    
         position: relative;
        display:flex;
        align-items: center;
        border: 1px solid $colors__lines;
        padding: 5px;
        padding-right: 25px;
        flex-wrap: wrap;
        //justify-content: space-between;
      }

      &__placeholder {
        color: $colors__gray;
      }

      &__list {
        overflow: hidden;
        transform: translateY(100%);
        display:flex;
        flex-direction: column;
        left: 0px;
        top:0px;
        width: 100%;        
        height: 100%;
        border: 1px solid $colors__lines;
        border-top: 0px;
        position:fixed;
        justify-content: space-between;
        background-color: $colors__white;
        @include transition(transform);
        z-index: 5;

        &--active {
          transform: translateY(0);
          @include transition(transform);
        }

        &__header {
          display:flex;
          justify-content: space-between;
          width:100%;
          padding: 10px;
          background-color: $colors__blue;
          color: $colors__white;
          @extend .font__body2;
          align-items: center;
          &__close {
            cursor: pointer;
          }
        }
        &__footer {
          display:flex;
          justify-content: space-between;
          width:100%;
          padding: 10px;
          border-top: 1px solid $colors__lines;
          &__count {
            color: $colors__gray;
          }
          &__close {
            color: $colors__coral;
            cursor: pointer;
          }
        }

        &__container {
          overflow-y: scroll;
          align-items:center;
          padding: 5px;
          flex-grow: 1;
        }

        &__topic {
          display: flex;
          margin: 15px 0;
          &__checkbox {
            -webkit-appearance: checkbox;
          }
        }       
      }     
    }

    &__submit {
      width: 100%;

      &__required {
        margin-bottom: $mobile-vertical-padding;
      }
    }

    &__input {
      margin-bottom: $mobile-vertical-padding;
      &__invalid-message{
        @extend .font__subhead2;
        color: $colors__coral;
        display: none;
        padding-left: 10px;
        text-transform: none;
        &--active{
          display: inline-block;
        }
      }
      &__label {
        @extend .font__subhead2;
        margin-bottom: 10px;
        display:flex;
       //justify-content: space-between;
        &__error {
          color: $colors__coral;
        }
      }
      &--hidden{
        display: none;
      }
      &__input {
        width:100%;
        padding: 5px;
        border: 1px solid $colors__lines;

        &--multi {
          height: 150px;
        }
        @include below(tablet) {
          &--multi {
            box-sizing: border-box;
          }
        }
        
      &--error {
        border-color: $colors__coral;
      }
      }
    }
  }
  
 @include below(tablet) {
    .subscribe__form__topics__list{ 
      height: 100% !important; //this is only to override the expand directive on mobile to avoid creating two elements
    }    
  }

  @include below(desktop) {
    &__intro {
      margin-top: 1.5rem;      
    }
    &__footer {
      margin-top: 1.5rem;
    }
  }

  @include above(tablet) {
    min-height: 513px;

    &__form {     
      margin-right: 94px;
      display: flex;    
      flex-wrap: wrap;
      justify-content: space-between;

      &__input {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &--hidden{
          display: none;
        }

        &--half {
          flex-basis: 48.5%;
        }
        &--full{
          flex-basis: 100%;
        }
        &--third{
          flex-basis: 23%;
        }
        flex-basis: 48.5%;
        //padding: 15px;
        box-sizing: border-box;

        &__input{
          &--multi{
            width: 100%;
            box-sizing: border-box;
          }
        }
        
      }
      &__topics {
        width: 100%;

        &__add {
          top: 5px;
        }

        &__list {
          position:relative;
          transform: translateY(0);
          visibility:hidden;
          @include transition(visibility);

          &--active {
            visibility: visible;
            display:flex;            
          }

          &__header {
            display: none;
          }
        }
      }
    }
  }

  @include above(desktop) {
    &__container {
      margin-top: $desktop-vertical-padding;
      flex-direction: row;

      &--shrink-padding {
        margin-top: calc($desktop-vertical-padding/2);
      }
    }
    &__description {
      flex-basis: 33.33%;
    }
    &__form-wrapper {
      flex-basis: 62.66%;
    }
    &__result-message {
      flex-basis: 62.66%;
    }
  }
}

.topic {
  background-color: $colors__teal;
  color: $colors__white;
  margin: 2px;
  padding: 2px 4px;
  @extend .font__body2;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  &__close {
    display: inline-flex;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    @extend .font__body2;
   @extend .font__body--semibold;
  }
}