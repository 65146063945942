@mixin placeholder() {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    @content;
  }

  &::-moz-placeholder { 
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @content;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    @content;
  }
}