.page-hero {
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: $colors__navy;
  background-position: center;
  &--pattern {
    background-size: auto;
  }
  background-size: cover;
  color: $colors__white;
  position: relative;
  
  &--dark-theme{
	  color: $colors__black;
  }
  
  &__hidden-title {
    visibility: hidden;
  }

  &__authors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @extend .font__body;
    @extend .font__body--extralight;
    margin-bottom: 5px;

    &__author {
      @extend .font__body--semibold;
      line-height: inherit;
      margin-right: 6px;  
      
      a{
        border-bottom: solid 1px;
        line-height: 1.1;
      }
    }
  }
  &__content {
    &__eyebrow {
      @extend .font__subhead2;
    }
  }  

  &--coral, &--blog {
    background-color: $colors__coral;
  }

  &--teal, &--publication {
    background: $colors__teal;
  }

  &--gray, &--advisory {
    background: $colors__gray;
  }

  &--cyan, &--News {
    background: $colors__cyan;
  }

  &--blue, &--presentation {
    background: $colors__blue;
  }
  &--white {
    background: $colors__white;
    border-top: 1px solid $colors__lines;
    border-bottom: 1px solid $colors__lines;
  }

  &.color {
    &__navy {
      background-color: $colors__navy;
    }
    &__navy--dark{
      background-color: $colors__navy--dark;
    }
  
    &__blue {
      background-color: $colors__blue;
    }
    &__blue--dark {
      background-color: $colors__blue--dark;
    }
  
    &__cyan {
      background-color: $colors__cyan;
    }
    &__cyan--dark {
      background-color: $colors__cyan--dark;
    }
  
    &__teal {
      background-color: $colors__teal;
    }
    &__teal-dark {
      background-color: $colors__teal--dark;
    }
  
    &__coral {
      background-color: $colors__coral;
    }
    &__coral--dark{
      background-color: $colors__coral--dark
    }
  
    &__yellow {
      background-color: $colors__yellow;
    }
    &__yellow--dark {
      background-color: $colors__yellow--dark;
    }
  
    &__gray {
      background-color: $colors__gray;
    }
    &__gray--dark {
      background-color: $colors__gray--dark;
    }  
  }

  &__date {
    @extend .font__body2;
  }
  &__video {
    position: absolute;
    right: 0; 
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    width: auto; 
    height: auto;
  }
  &__event {
    color: $colors__black;
    & &__left {
      width: 100%;
    }
    &__title {
      @extend .font__h1;
      margin-top: $mobile-vertical-padding;
      color: $colors__aqua;
      h1 {
        @extend .font__h1;
      }
    }
    &__location {
      @extend .font__body;
      margin-bottom: $mobile-vertical-padding;
    }
  }
  &__container {
    @include container();
    position: relative; //for absolute gradient element to be underneath text, etc
    display: flex;
    &--line {
      width: 100%;
      flex-direction: column-reverse;
    }
  }

  &__title {
    @extend .font__h1;

    @include above(tablet) {
      line-height: 96px;    
    }
    @include below(tablet) {
      line-height: 42px;    
    }

    &--light {
      @extend .font__subhead1;
      @extend .font__subhead1--extralight;
    }

    &--alt {
      @extend .font__subhead3;
    }

    &__practice {
      @extend .font__body2;
      font-weight: $fonts__semibold;

      &__breadcrumb {
        @extend .font__body2;
        font-weight: $fonts__regular;
      }
    }
  }

  &__subtitle {
    margin-top: 10px;
    @extend .font__body--extralight;
  }
  &__center {
    @include container();
    text-align: center;
    width: 100%;
    margin: 0 auto;
	  padding-top: 72px;
    padding-bottom: 72px;
  }
  
  &__right {
    align-self: flex-end;
    width:45%;
    margin-right: 30px;
    &__img {
      display: flex;
      img {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  &__left {
    @include above(tablet) {
      min-height:520px;      
    }    

    margin-left:0;
    &__office {
      &__title {
        @extend .font__h1;
      }      
      &__address {
        margin-top: $mobile-vertical-padding;
      }
    }
  }
  
  &__left{
    padding-top: 72px;
    padding-bottom: 72px;
	  display: flex;
    flex-direction: column;
    justify-content: center;
	  width: 100%;
  }

  &__text {
    @extend .font__body;

    p, a, li, span {
      @extend .font__body;
    }

    &__subheader {
      margin-bottom: $mobile-vertical-padding;
      @extend .font__body--extralight;
    }
    &__social {
      display: flex;
      flex-direction: row;
      margin-bottom: $mobile-vertical-padding;
	  &--bottom{
		margin-bottom: 0;
		margin-top: $mobile-vertical-padding;
	  }
      &__icon {
        margin-right: 20px;
        @include transition(opacity);
        &:hover {
          opacity: .5;
          @include transition(opacity);
        }
        &:before{
          font-size: 20px;
        }
      }
    }
    &--alt {
      @extend .font__h1;
      margin-bottom: $mobile-vertical-padding;
      &:only-child {
        margin-bottom: 0;
      }
    }
    &--content {
      @extend .font__h2;
    }
    margin-top: 32px;
  }

  &__cta {
    margin-top: 32px;
  }

  &__gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(270deg, rgba(30, 40, 60, 0.00) 0%, $colors__black 100%);

    &--content {
      background-image: linear-gradient(to right, rgba(0, 0, 0, .45) 100%, rgba(0,0,0,0) 75%);
      @include above(mobile) {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .45) 25%, rgba(0,0,0,0) 75%);
      }
    }
  
    &--center {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }
    
  }

  @include above(mobile) {
    &__container {
      &--line {
        flex-direction: row;
      }
    }

	  &__left {
      width:65%;
      //display:inline-block;
    }

    &__right {
	    align-self: center;
    }

	  &__center, &__left {
	    padding-top: 72px;
      padding-bottom: 72px;
	  }
  }

  @include above(tablet) {
    min-height:520px;

    &__right {
      display:inline-block;
    } 
  }

  @include above(desktop) {
    &__center, &__left, &__right {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    &__left, &__right {
      width: 70%;
    }
    &__left {
      padding-left:0;
      &__office {
        &__address {
          margin-top: $desktop-vertical-padding;
        }
      }
    }

    &__text {
      margin-top: 18px;
    }

    &__cta {
      margin-top: $desktop-vertical-padding;
    }
  }
}

// @media(min-width: $image-width) {
//   min-height:660px;

//   &__left {
//     width:65%;
//     padding-top: 100px;
//     padding-bottom: 100px;
//   }
// }

.video-hero {
  overflow: hidden;
  position: relative;
  height:660px;

  &__video {
    object-fit: cover;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }

  @media(min-width: $image-width) {
    min-height:660px;
  }
}