.button {
  
  @extend .font__subhead2;
  line-height: 1;
  padding: 8px 20px;
  padding-bottom: 7px;
  border: solid 2px;
  transition: padding $hover-speed ease-in-out, margin $hover-speed ease-in-out;
  color: $colors__coral;
  white-space: nowrap;

  &__row {
    padding: 1rem;
    display: flex;
    margin: .5rem -1rem;

    &--dark {
      background-color: $colors__navy; 
    }

    &--dark-image {
      background-color: $colors__navy; 
      background-image: url(https://res.cloudinary.com/simpleview/image/upload/c_fill,f_auto,h_322,q_75,w_500,o_25/v1/clients/chicago/Aerial_CAC_e5dcacf0-7c13-49be-b765-d928be6d44c7.jpg);
      background-size: cover;
      background-position: center;
    }
  }

  &__spacing-top {
	  margin-top: $mobile-vertical-padding;
  }

  &__row-item:not(:first-child) {
    margin-left: 1rem;
  }

  &--yellow,
  &--on-dark-image {
    color: $colors__yellow;
  }

  &--white,
  &--on-dark-background {
    color: $colors__white;
  }

  &--light-font {
    font-weight: 500;
  }

  &--hover,
  &:hover,
  &:focus {
    padding: 12px 24px;
    padding-bottom: 11px;
    margin: -4px;
  }

  &:focus {
    outline:0;
  }

  &--inactive,
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    &:hover,
    &:focus {
      margin: 0;
      padding: 8px 20px;
      padding-bottom: 7px;
    }
  }

  &__see-more {
    margin-bottom: 30px;
  }  
  
  &__container {
		margin-top: $desktop-vertical-padding;
  }  
}