.sitesearch-listing {
    @extend .font__body2;
    color: $colors__black;
    margin-top: 1.7em;

    &__name {        
        @extend .font__body2--semibold;
        line-height: 1.333rem;
    }   

    &__link {
        .sitesearch-listing__name {
            @include links__hover-black();
        }
    }

    &__title {
        @extend .font__body2;
    }

    &__sub {
        @extend .font__body4;
    }

    &__sub-line {
        margin-bottom: 5px;
    }

    &__description {
        
    }
}