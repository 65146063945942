@mixin links__hover-yellow() {
    @include transition(color);

    &:hover {
        color: $colors__yellow;
    }
}

@mixin links__hover-black() {
  @include transition(color);

  &:hover {
      color: $colors__black;
  }
}


  // "Line animates in from the left, and out from the right"
@mixin links__swoopy($color: $colors__white) {
  position: relative; 

  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0px;
    height: 1px;
    margin: 1px 0 0;
    @include transition(all 0.3s ease-out, opacity .1s ease-out 0.2s);
    // transition-duration: 0.5s;
    opacity: 0;
    background-color: $color;

    right:0;
    left: auto;
  }

  &:hover:after {
    left: 0;
    right: auto;

    @include transition(all 0.25s ease-out);

    width: 100%;
    opacity: 1;
  }
}