.rte-list {
  width: 100%;

  &--awards {
   

    @include above(desktop) {
      .rte-list__title {
        //width: 38%;
      }

      .rte-list__items {
        //width: 45%;
      }
    }
  }

  &__title {
    @extend .font__subhead2;

    &--border-top-half {
      padding-top: $desktop-vertical-padding;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        background: $colors__lines;
        margin-bottom: $desktop-vertical-padding;
      }
    }
  }

  &__award__container {
    display: flex;
    flex-direction: column-reverse;
  }

  &__items {
    margin-top: 24px;

  }

  &__item {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__content {
    @extend .font__body2;
    ul {
      li {
        &:not(:first-child) {
          margin-top: 24px;
        }
      }
      
      ul {
        list-style: disc;
        padding-left: 40px;
        margin-right: 20px;
      }
    }
    &.rich-text--smaller{
      ul {
        li {
          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &__more {
    margin-top: 24px;
  }

  @include above(tablet) {
    &__award__container {
      flex-direction: row;
    }
    &__title {}

    &__items {}

    &__more {
      margin-top: $desktop-vertical-padding;
    }
  }

  @include above(desktop) {

    display: flex;
    justify-content: space-between;
    &--awards {
      justify-content: left;
    }
    &__award__container {
      width: 69%;
      justify-content: space-between;
    }
    &__title {
      width: 31%;
	  margin-right: 20px;

      &:before {
        content: none;
      }
    }

    &__items {
      margin-top: 0;
      width: 69%;
      display: flex;
      flex-direction: column;
	  
	  &--no-section-title {
	    width: 100%;
	  }
	  
      &--border-top-half {
        position: relative;

        &:before {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          background: $colors__lines;
          margin-bottom: $desktop-vertical-padding;
        }
      }
    }

    &__item {
      // flex: 1; //breaks IE
    }
  }
}

.rte-list-condensed {

  //
  p {
    @extend .font__body;
  }

  @include above(desktop) {
    &:not(:last-child) {
      margin-bottom: $desktop-vertical-padding;
    }
  }
}

.rte-list__ {}

.rte-list-alternate {


  width: 100%;

  &__title {
    @extend .font__h2;
  }

  &__items {
    margin-top: 24px;
  }

  &__item {
    margin-top: 32px;
    padding-top: 32px;

    &:not(:first-child) {

      border-top: solid 1px $colors__lines;
    }

    &:last-child {
      margin-bottom: 32px;
      padding-bottom: 32px;
      //border-bottom: solid 1px $colors__lines;
    }

  }

  &__label {
    @extend .font__body;
    @extend .font__body--semibold;
    margin-bottom: $mobile-vertical-padding;
  }

  &__content {
    @extend .font__body2;

    &__text {
      margin-bottom: 12px;
    }
  }

  @include above(desktop) {
    &__items {}

    &__item {
      display: flex;
      justify-content: space-between;
    }

    &__label {
      width: 34%;
      padding-right: 10px;

    }

    &__content {
      width: 51%;
    }
  }
}