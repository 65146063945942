.office-card {
  width:100%;
  margin-bottom: $desktop-vertical-padding;
  &__image {
    width:100%;
    img {
      width:100%
    }
  }
  &__title {
    margin: $mobile-vertical-padding 0;
    @extend .font__h2;
  }
  @include above(mobile) {
    max-width:33.33%;
    padding:25px 10px;
  }

}
.office-card-container {
  width: 100%;
  display:flex;
  flex-direction:column;
  @include above(mobile) {
   flex-direction:row;
   flex-wrap: wrap;
  }
}