
@keyframes minimalDropdownFadeIn {
    0% {
      opacity: 0;
      transform: translate3d(0, -50%, 0);
    }
  
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  } 

  @keyframes homepageArrowFadeIn {
    0% {
      opacity: 0;
    }

    80% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  } 