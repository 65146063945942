.people-search {
  width:100%;
  &__header {
    @extend .font__subhead2;
  }
  &__filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: $mobile-vertical-padding;
    margin-bottom: $mobile-vertical-padding;
    align-items: flex-start;
    &__left {
      width: 100%;
      margin-bottom: $mobile-vertical-padding;
    }
    &__right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    &__container {
      width: 100%;
    }
    &__filter {
      margin: 12px 0px;
    }
    
  }
  &__search {
    @extend .icon;
    @extend .icon__search;
    color: $colors__teal;
    &:before {
      font-size: 21px;
    }
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
   &__input {
    @extend .font__subhead1;
    @extend .font__subhead1--extralight;
    margin-left: 10px;
    &::placeholder {
      @extend .font__subhead1;
      @extend .font__subhead1--extralight;
      color: $colors__black;
    }
   
   }
   &:focus-within {
    border-color: $colors__teal;
  } 
    border-bottom: 1px solid $colors__lines;
  }
  &__people {
    margin-top: $mobile-vertical-padding;
    &__no-results {
      @extend .font__body;
      @extend .font__body--semibold;
    }
  }
  &__clear {
    margin-top: $mobile-vertical-padding;
  }
  &__see-all {
    margin-top: $mobile-vertical-padding;
    &__button {
     
    }
  }
  @include above(desktop) {


    &__filters {
      flex-direction: row;
      &__left {
        flex-basis: 33.33%;
      }
      &__right {
        flex-basis: 56.66%;
      }
      &__container {
        max-width: 200px;
      }
      &__filter {
        width: 100%;
        margin-top: 0px;
      }
     
    }
    &__search {
      width: 350px;
    }
  }
}