.initiative-container {
  width:100%;
  
  &__grid {
    display:flex;
    flex-wrap:wrap;    
    //margin-left: -20px;
    //margin-right: -20px;
    margin-left: -10px;
    margin-right: -10px;
    flex-direction:column;
    justify-content: center;
  }
  
  @include above(mobile) {
    &__grid {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.initiative {
  //padding: 20px;
  padding: 10px;
  width:100%;
  
  &__image {
    background: rgba($colors__smoke, .3);
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    padding-top: 75%;
    position:relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      width: 100%;
      @include transition(transform, filter);
    }
  }

  @include above(mobile) {
    width: 50%;
  }

  @include above(tablet) {
    width: 33%;
  }

  @include above(desktop) {
    width: 25%;

    &__image {
      img {
        filter: grayscale(100%);
        &:hover {
          filter: grayscale(0);        
	        transform: translate(-50%, -50%) scale(1.15);
          @include transition(transform, filter)
        }
      }
    }
  }
}

.break {
  flex-basis: 100%;
  width: 0px; 
  height: 0px; 
  overflow: hidden;
  display:none;

  @include above(tablet) {
    display:block;
  }
}
