//experience item has ajax and non ajax html components

.experience {
  width: 100%;
  
  &__title {
    @extend .font__h2;
    margin-bottom: $mobile-vertical-padding;
  }

  &__item {
 
     &:not(:first-child) {
      margin-top: 32px;
      padding-top: 32px;
      border-top: solid 1px $colors__lines;
     }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        //border-bottom: solid 1px $colors__lines;
      }
     
  }
  &__prior-footnote{
    margin-top: $desktop-vertical-padding;
  }
  &__more-button {
    padding-top: 20px;
  }
  &__label {
    @extend .font__body;
    @extend .font__body--semibold;
  }
  &__content {
    @extend .font__body2;
	
  }
  @include above(desktop) {
    &__title {
      margin-bottom: $desktop-vertical-padding;
    }
	
    &__item {
      display: flex;
      justify-content: space-between;
    }

    &__label {
      width: 34%;
      padding-right: 10px;
      
    }

    &__content {
      width: 51%;
    }
  }
}