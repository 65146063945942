.link-list {
  &:not(:first-child) {
    margin-top: 24px;
  }
  &__header {
    @extend .font__body;
    @extend .font__body--semibold;
	
	& + .link {
	  margin-top: 5px;
	}
  }
  &__body {
    @extend .font__body2;
	margin-top: 5px;
	
	& + .link {
	  margin-top: 5px;
	}
	width: 100%;
	p {
	  @extend .font__body2;
	}
  }
  &__title {
    @extend .font__body;
    @extend .font__body--semibold;
    &--coral {
      color: $colors__coral;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .long-link {
      &:after {
        content:none;
      }
    }
    &__link {
      &__label {
        border-bottom: 1px solid;
        &--bold {
          font-size: 15px;
          @extend .font__body--semibold;
        }
      }
    }
  }
  &__title,
  &__links {
    &:not(:first-child) {
      margin-top: 4px;
    }
  }
}