.tab-content {
  @include transition(opacity);

  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition-delay: $transition__timing;
  display: none;

  &--active {
    display: block;
    opacity: 1;
    position: relative;
    // z-index: 2;
  }

  // .tabby-area__frame--transition & {
  //   &--active {
  //     opacity: 0;
  //     transition-delay: 0s;
  //   }
  // }

}

.tab-content> :last-child.cta-component-carousel,
.tab-content> :last-child.cta-component {
  margin-bottom: 72px;


}