@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8uucnu');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8uucnu#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8uucnu') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8uucnu') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8uucnu##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin font-family-icomoon() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon {
  &__dropdown{
    background-color: $colors__white;
  }
  &:before {
    @include font-family-icomoon();
  }
}


.icon__chevron-small-down {
  &:before {
    content: $icon__chevron-small-down; 
  }
}
.icon__chevron-small-left {
  &:before {
    content: $icon__chevron-small-left; 
  }
}
.icon__chevron-small-right {
  &:before {
    content: $icon__chevron-small-right; 
  }
}
.icon__chevron-small-up {
  &:before {
    content: $icon__chevron-small-up; 
  }
}
.icon__chevron-thin-down {
  &:before {
    content: $icon__chevron-thin-down; 
  }
}
.icon__chevron-thin-left {
  &:before {
    content: $icon__chevron-thin-left; 
  }
}
.icon__chevron-thin-right {
  &:before {
    content: $icon__chevron-thin-right; 
  }
}
.icon__chevron-thin-up {
  &:before {
    content: $icon__chevron-thin-up; 
  }
}

.icon__download {
  &:before {
    content: $icon__download; 
  }
}
.icon__twitter {
  &:before {
    content: $icon__twitter; 
  }
}
.icon__vimeo {
  &:before {
    content: $icon__vimeo; 
  }
}
.icon__search {
  &:before {
    content: $icon__search; 
  }
}
.icon__facebook {
  &:before {
    content: $icon__facebook; 
  }
}
.icon__instagram {
  &:before {
    content: $icon__instagram; 
  }
}
.icon__linkedin {
  &:before {
    content: $icon__linkedin; 
  }
}
.icon__print {
  &:before {
    content: $icon__print; 
  }
}
.icon__quotes {
  &:before {
    content: $icon__quotes; 
  }
}
.icon__close {
  &:before {
    content: $icon__close; 
  }
}
.icon__thin-close {
  &:before {
    content: $icon__thin-close; 
  }
}
.icon__external {
  &:before {
    font-size:18px;
    padding-right:.1em;
    top: .05em;
    position: relative; 
    content: $icon__external; 
  }
}
.icon__menu {
  &:before {
    content: $icon__menu; 
  }
}
.icon__minus {
  &:before {
    content: $icon__minus; 
  }
}
.icon__plus {
  &:before {
    content: $icon__plus; 
  }
}
.icon__share {
  &:before {
    font-size:16px;
    padding-right: .15em;
    content: $icon__share; 
  }
}

.icon__map {
  &:before {
    content: $icon__map; 
  }
}

.icon__play3 {
  &:before {
    content: $icon__play3; 
  }
}
