.link {
  // $hover-speed: 150ms;

  @extend .font__body3;
  &--body4 {
    @extend .font__body4;
  }
  position: relative;
  //white-space: nowrap;
  transition: color $hover-speed ease-in-out;

  
  &::after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    border-bottom: solid 1px;
  }
  &--active {
    @extend .font__body3--semi-bold;
    &::after {
      content: none;
    }
  }
  &__row {
    display: flex;
    .link:not(:first-child) {
      margin-left: 1rem;
    }
  }

  &--hover,
  &:hover:not(.link--active),
  &:focus:not(.link--active) {
    color: $colors__coral--dark;
  }

  &--inactive,
  [disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &--external {
    @extend .link--has-icon;
    @extend .icon;
    @extend .icon__external;
  }

  &--has-icon {
    margin-bottom: 1px;
    &::before {
      display: inline-block;
      transform: translateY(3px);
      padding-right: .25em;
      border-color: rgba(0, 0, 0, 0);
    }
    &::after {
      left: 1.25em;
    }
    &:hover,
    &:focus {
      &:not(.link--inactive),
      &[disabled] {
        &::before {
          transition: color $hover-speed ease-in-out;
          color: $colors__coral;
        }
      }
    }
  }

  &--swoopy {
    @include links__swoopy()
  }
}
.icon__print {
  display:none;
  @include above(tablet) {
    display:block;
  }
}