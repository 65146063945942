.searchbar {
  $spacing-y: 10px;
  $spacing-x: 14px;

  width: 100%;
  box-sizing: border-box;
  padding: $spacing-y $spacing-x;
  display: flex;

  &--no-padding {
    padding: 0;
  }

  &__left {
    position: relative;
    flex: 1;
  }

  &__right {
    position: relative;
    margin-left: 1em;
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
  }

  &__placeholder {
    position: absolute;
    left: 0;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
  }

  &__button {
    @include fadeOnHover();
  }

  &--dark {
    background-color: $colors__blue--dark;
  }
}
