//font size h2
$homepage-inquiry-dropdown-height: 28px * 1.5;

//for the intro screen with the text
$homepage-inquiry-side-padding: 18px;
$homepage-inquiry-side-padding-desktop: 30px;
$homepage-inquiry-vertical-padding: 18px;


//for the panels that pop up (to the side on desktop)
$homepage-panel-side-padding: 16px;
$homepage-panel-side-padding-desktop: 40px;

//margins and padding

$desktop-vertical-padding: 48px;
$mobile-vertical-padding: 24px;

$mobile-vertical-padding-custom: 18px;

$image-width: 1440px;

$link-spacing: 44px;

$navbar-height: 66px;