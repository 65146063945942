.contact {
  h3,
  h4,
  h5,
  h6,
  p {
    @extend .font__body;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  display: flex;
  flex-direction: column;

  &__right,
  &__left {
    flex: 1;
  }

  &__left {
    &__header {
      @extend .font__h2;
      margin-top:-4px;
      margin-bottom: 1em;
    }
  }

  &__right {
    &__text {
	  @extend .font__body;
      margin-bottom: 2em;
    }
  }

  @include above(mobile) {
    flex-direction: row;

  }
}

