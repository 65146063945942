.expertise {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  &-title {
    text-transform: uppercase;
    @extend .font__subhead2;
  }

  &__main {
    flex-direction: column-reverse;
  }

  &__side-nav {

    width: 50%;
    padding: 1rem;

    //height: 80vh;
    //overflow-y: hidden;    
    &__container {
      &--sticky {
        position: fixed;
        top: 135px;
      }

      &__header {
        margin-top: 5px;
        @extend .font__body;
        @extend .font__body--semibold;
      }

      &__text {
        margin-bottom: 20px;
      }
    }
  }

  &__main {
    width: 100%;
    display: flex;
  }
  @include above(tablet) {
    &__main {
      flex-direction: row-reverse;
    }
    
  }
}

.industries {
  @include above(tablet) {
    border-right: 1px solid $colors__lines;
  }
}
.expertise__section {
  //padding:20px;
  @include above(tablet) {
    width: 50%;
  }
}
.practices {
  @include above(tablet) {
    padding-left: 75px;
  }
}
.expertise-item {
  margin: 20px 0;
  //padding-right: 40px;
  cursor: pointer;
  .link {
    &:after {
      content: none;
    }
  }
  &__container {
    display: flex;
    align-items: center;
    //justify-content: space-between;
  }

  &__subnav {
    overflow: hidden;
  }

  &__secondary-subnav{
    margin-left: 15px;
  }

  &__header {
    position: relative;
    
    &__name-part{
      white-space: nowrap;
      &:after {
        font-size: .4em;
        vertical-align: middle;
        margin-left: 10px;
        //position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--closed {
      .expertise-item__header__name-part{
        &:after {
          @include font-family-icomoon();
          content: $icon__plus;
  
        }
      }
      

    }

    &--open {
      .expertise-item__header__name-part{
        &:after {
          @include font-family-icomoon();
          content: $icon__minus;
  
        }
      }
      

    }

    &--active {
      @extend .font__h2;
     
    }

    &--inactive {
      @extend .font__h2;
      @extend .font__h2--extralight;
    
    }
  }
 
}

.expertise-accordion-item {
  margin: 10px 0;
  overflow: hidden;
  cursor: pointer;

  &__header {

    &--active {
      @extend .font__body2--semibold;

    }

    &--inactive {
      @extend .font__body2;

    }
  }

  @include above(desktop) {
    &__side-nav {
      width: 30%;
    }
  }
}