* {
    &:focus {
    outline: none;
    }
}
.live-search-results {
    color: $colors__black;

    &__side-scroll-wrapper {
        background-color: $colors__white;
        padding: 16px;
    }

    &__side-scroll {
        @extend .font__body2;
        overflow: auto;
        white-space: nowrap;

        // @include hide-scrollbar; //TODO: this makes desktop scrolling impossible

        //white scroll fade-out
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            height: 1em;
            margin-top: -1em;
            width: 7em;
            background: linear-gradient(transparent, #FFF) left repeat; 
            background: linear-gradient(to right, transparent, rgba(255,255,255,1) 100%);
            pointer-events: none;
        }
    }

    &__side-scroll-item {
        margin-right: 2em;
        color: $colors__gray;
        display: inline-block;

        &.active {
            color: $colors__black
        }
    }

    &__section {
        // margin: 1em 0;
        margin-bottom: 2em;
    }

    &__see-all {
        @extend .font__body3;
        color: $colors__black;
        //float: right;
        position:relative;
        &:focus {
            outline: none;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 3px;
            left: 0;
            right: 0;
            border-bottom: solid 1px;
          }
    }

    &__header {
        color: $colors__teal--dark;
        padding: 1em 0;
        border-bottom: 1px solid $colors__teal--dark;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        &--no-see-all{
            flex-direction: row;
        }

        &--cyan {
            color: $colors__cyan--dark;
            border-color: $colors__cyan--dark;
        }

        &--blue {
            color: $colors__blue--dark;
            border-color: $colors__blue--dark;
        }

        &--orange {
            color: $colors__coral--dark;
            border-color: $colors__coral--dark;
        }
    }

    &__header-text {
        @extend .font__subhead2;
    }

    &__no-results {
      margin-top: $mobile-vertical-padding;
      color: $colors__black;
      @extend .font__body;
      @extend .font__body--semibold;
    }

    @include above(desktop) {
        &__side-scroll {
            &:after {
                display: none;
            }
        }

        &__side-scroll-wrapper {
            padding: 20px $homepage-panel-side-padding-desktop;
        }
    }
}