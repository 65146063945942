.font {
  &__h1 {
    font-weight: $fonts__semibold;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0px;
  }
  &__h2, &__h2--desktop {
    font-weight: $fonts__semibold;
    font-size: 2.25rem;
    line-height: 3rem;
    letter-spacing: -0.3px;
    &--extralight {
      font-weight: $fonts__extralight;
      letter-spacing: 0px;
    }
    &--extrasmall {
      font-size:20px;
    }
  }
  &__subhead1 {
    font-weight: $fonts__regular;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.4px;
    &--extralight {
      font-weight: $fonts__extralight;
    }
  }
  &__subhead2 {
    font-weight: $fonts__semibold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    &--regular {
      font-weight: $fonts__regular;
    }
  }
  &__subhead3 {
    font-weight: $fonts__regular;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  }
  &__body {
    font-weight: $fonts__regular;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0px;
    &--semibold {
      font-weight: $fonts__semibold;
    }
    &--extralight {
      font-weight: $fonts__extralight;
    }
  }
  &__body2 {
    font-weight: $fonts__regular;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0px;
    &--semibold {
      font-weight: $fonts__semibold;
    }
    &--extralight {
      letter-spacing: .5px;
      font-weight: $fonts__extralight;
    }
  }
  &__body3 {
    font-weight: $fonts__regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    
    &--semi-bold {
      font-weight: $fonts__semibold;
    }
    &--extralight {
      font-weight: $fonts__extralight;
    }
  }
  &__body4 {
    font-weight: $fonts__regular;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.75px;
    &--extralight {
      font-weight: $fonts__extralight;
    }
  }
  &__subhead3 { // 'homepage only'
    font-weight: $fonts__regular;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  }

  @include above(tablet) {
    &__h1 {
      // font-weight: $fonts__semibold;
      font-size: 84px;
      line-height: 84px;
      letter-spacing: -1px;
    }
    &__h2 {
      // font-weight: $fonts__semibold;
      font-size: 50px;
      line-height: 57px;
      letter-spacing: -1px;
      &--extralight {
        // font-weight: $fonts__extralight;
        letter-spacing: 0px;
      }
    }
    &__subhead1 {
      // font-weight: $fonts__regular;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -0.4px;
      &--extralight {
        font-weight: $fonts__extralight;
      }
    }
    &__subhead2 {
      // font-weight: $fonts__semibold;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1.3px;
      text-transform: uppercase;
    }
    
    &__body {
      font-weight: $fonts__light; //DIFFERENT
      font-size: 21px;
      line-height: 31px;
      letter-spacing: -0.4px;
      &--semibold {
        font-weight: $fonts__semibold;
      }
    
    }

    //no change for body 2, 3, 4

    &__subhead3 { // 'homepage only'
      // font-weight: $fonts__regular;
      font-size: 18px;
      line-height: 23px;
      // letter-spacing: 1.3;
      // text-transform: uppercase;
    }
  }

  //change size at the desktop breakpoint, not tablet

  @include above(desktop){ 
    &__h2--desktop {
      font-size: 50px;
      line-height: 57px;
      letter-spacing: -1px;
      &--extralight {
        letter-spacing: 0px;
      }
    }
  }
}