.search-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.25);
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s linear;

    &__show {
        visibility: visible;
        opacity: 1;    

        .search-loader__spinner {
            border: 15px solid $colors__gray--dark;
            border-top: 15px solid $colors__blue;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            animation: spin 2s linear infinite;
            position: absolute;        
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -50px;
        }
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}