.text-carousel {
  //display: flex;

  &__dot {
    opacity: .5;
    font-size: .75em;
    margin-right: .5em;
    &--active {
      opacity:1;
    }
    &:focus {
      outline: none;
    }

  }
  &__container {
    flex-direction:column;
    overflow:hidden;
    display: flex;
  }
  &__left {
    flex: 1;
    display:inline-block;

    @include above(mobile) {
      flex: none;
      padding-right: 25px;
    }

    @include above(tablet) {
      flex: none;
      padding-right: 75px;
    }

    &__header {
      @extend .font__h2;
      margin: 1em 0;
    }
    &__menu {
      @include themify($themes) {
        color: themed('color');  
        //margin: .5em 0;
      }    

      &__item {
        display:none;
        @extend .font__h2;
        cursor: pointer;
        opacity: .25;
        &--active {
          opacity: 1;
        }        
      }

      &__header {
        @extend .font__h2;
        margin-bottom: .5em;
        @include themify($themes) {
          color: themed('color');  
        } 
      }
    }
  }

  &__right {
    display:inline-flex;
    flex: none;
    position:relative;
    flex-direction:row;
    flex-wrap: nowrap;
    //overflow:auto;
    @include transition(transform);
    //transition: transform 5s ease;
    &__container {
      position:relative;
      flex: 1 0 100%;
      @extend .font__body;
	 
      p {
        @extend .font__body;
      }
   
    }
    &__image-container {
      display:flex;
      flex-direction: column;
      &__image{
        width:100%;        
        margin-bottom: 1em;
      }
      &__caption {
        @extend .font__body4;
      }
    }
    &__quote {
      position:relative;
      &:before {
        @include font-family-icomoon();
        content: $icon__quotes;
        font-size: 30px;
        @include themify($themes) {
          color: themed('color');
        }
        display:block;
        margin-bottom: 25px;
      }
    }
  }
  @include above(mobile) {
    &__container {
      flex-direction: row;
      min-height: 330px;
    }
    &__left {
      width:50%;
      &__header {
        margin: 0;
      }
      &__menu {
        &__item {
          display:block;
        }
      }
    }
    &__right {
      width: 50%;
      flex: 1;
      display: inline-block;
      &__container {
        opacity:0;
        visibility:hidden;
        height:0;
        &--active {
          height:auto;
          visibility:visible;
          opacity:1;
          transition: opacity 1s ease;
        }
      }
    }
    flex-direction:row;
    &__dots {
      display:none;
    }
    min-height:300px;
  }
  @include above(tablet) {


  }
  @include above(desktop) {
    &__container {
      min-height:400px;
    }

  }
}