.event-detail {
  margin: $mobile-vertical-padding 0;
  &__hosted {
    @extend .font__subhead2;
    color: $colors__blue--dark;
  }

  &__header {
    @extend .font__body;
    @extend .font__body--semibold;
    word-break: break-word;
  }

  &__content {
    @extend .font__body3;
    word-break: break-word;
    
    &-container {
      margin-bottom: 0.5rem;

      a {
        transition: color $hover-speed ease-in-out;
        
        &:hover {
          color: $colors__coral;
        }
      }
    }
  }

  &__speaker {
    margin-bottom: 0;
  }

  &__image {
    width: 100%;
    margin-bottom: $mobile-vertical-padding;
  }

  &__related-practices {
    margin-top: $mobile-vertical-padding;

    &__header {
      @extend .font__body;
    }

    &__practice {
      @extend .font__body;
      @extend .font__body--semibold;

      &+ .event-detail__related-practices__practice {
        &:before {  
          // content: ', ';                
          // font-weight: 100;
          // margin-left: -.333rem
        }
  
        &:last-child {
          &:before {   
            // content: ', and';  
            // margin-right: .333rem;
            // font-weight: 100;
            // margin-left: 0;
          }
        } 
      }
      
      @include below(desktop) {
        display: block;
        &+ .event-detail__related-practices__practice {
          &:before {  
            content: '';
            margin-left: 0;
          }
          &:last-child {
            &:before {   
              content: '';  
              margin-right: 0;              
            }
          } 
        }
      }
    }
  }

  &__separator {
    margin-left: -4px;
    font-weight: 100;
    font-size: 21px;    

    @include below(desktop) {
      display: none;      
    }
  }

  @include above(desktop){
    &__content-link {
      transition: transition(color);
      &:hover{
        color: $colors__coral--dark;
      }
    }
  }
}