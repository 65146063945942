.tombstone-container {
  width:100%;

  &__header {
    @extend .font__h2;
    margin-bottom: $mobile-vertical-padding;
  }

  &__subheader {
    @extend .font__body;
    margin-bottom: $mobile-vertical-padding;
  }

  @include above(tablet) {
    &__header {
      margin-bottom: $desktop-vertical-padding;
    }

    &__subheader {
      //max-width: 700px;
      margin-bottom: $desktop-vertical-padding;
    }
  }
}

.tombstone-grid-container {
  margin-bottom: $mobile-vertical-padding;
  &__grid {
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.tombstone-item {
  display: flex;
  flex-direction: column;
  padding-top: $mobile-vertical-padding;
  padding-bottom: $mobile-vertical-padding;
  //align-items: center;

  &:not(:first-child) {
    border-top: solid 1px $colors__lines;
  }

  .section--component & {
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__logo {
    display: flex;
    margin-bottom: $mobile-vertical-padding;
    align-self: flex-start;

    &__image {
      max-width: 100%;
	  
      @include above(mobile) {
        max-width: 215px;
        margin-right: 10px;
      }

      img {
        width:100%;        
      }
    }
  }
  
  &__text {
    margin: auto 0;
    width: 100%;
    
    &__header {
      @extend .font__body;
      @extend .font__body--semibold;
	    margin-bottom: 10px;
    }

    &__body {
      a {
        @extend .font__body2;
        @extend .font__body2--semibold;
      }
    }
  }

  &__link {
	  margin-top: 10px;
	}

  @include above(tablet) {
    flex-direction: row;

    &__logo {
      flex-grow: 1;
      flex-basis: 50%;
      justify-content: center;
      margin-bottom:0px;
    }

    &__text {
      flex-grow: 1;

    }
  }
}

.tombstone-grid-item {
  //margin: 20px;
  padding:20px;
  margin-bottom: $mobile-vertical-padding;

  &__logo {
    background-color: rgba($colors__smoke, .3);
    margin-bottom: $mobile-vertical-padding;
    display: flex;
    justify-content: center;
    padding: 48px 0;
    height: 250px;
	  width: 100%;
	
    &__image {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        max-height:153px;
        max-width:300px;
      }
    }    
  }

  &__link {
    margin-top: 1.25rem;
  }

  &__text {
    &__header {
      //margin-bottom: $mobile-vertical-padding;
      @extend .font__body;
      @extend .font__body--semibold;
    }

    &__body {
      //margin-top: 1.25rem;
      padding: .3em 0;

      a {
        margin-top: $mobile-vertical-padding;
        position:relative;
        overflow:hidden;

        &:before {
          @include font-family-icomoon();
          content: $icon__external;
        }

        &:after {
          content:'';
          position:absolute;
          bottom:0px;
          left:20px;
          background: black;
          height:1px;
          width:100%;
        }
      }
    }
  }

  @include above(tablet) {
    width: 50%;
  }

  @include above(desktop) {
    width: 33%;
  }
}