$fonts__extralight: 200;
$fonts__light: 300;
$fonts__regular: 400;
$fonts__semibold: 600;

// Extralight
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-Extralight.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-Extralight.woff') format('woff'),
       url('/assets/public/fonts/Graphik-Extralight.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-Extralight.svg#Graphik') format('svg');
  font-weight: $fonts__extralight;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-ExtralightItalic.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-ExtralightItalic.woff') format('woff'),
       url('/assets/public/fonts/Graphik-ExtralightItalic.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-ExtralightItalic.svg#Graphik') format('svg');
  font-weight: $fonts__extralight;
  font-style: italic;
}
// Light
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-Light.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-Light.woff') format('woff'),
       url('/assets/public/fonts/Graphik-Light.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-Light.svg#Graphik') format('svg');
  font-weight: $fonts__light;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-LightItalic.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-LightItalic.woff') format('woff'),
       url('/assets/public/fonts/Graphik-LightItalic.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-LightItalic.svg#Graphik') format('svg');
  font-weight: $fonts__light;
  font-style: italic;
}
// Regular
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-Regular.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-Regular.woff') format('woff'),
       url('/assets/public/fonts/Graphik-Regular.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-Regular.svg#Graphik') format('svg');
  font-weight: $fonts__regular;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-RegularItalic.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-RegularItalic.woff') format('woff'),
       url('/assets/public/fonts/Graphik-RegularItalic.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-RegularItalic.svg#Graphik') format('svg');
  font-weight: $fonts__regular;
  font-style: italic;
}
// Semibold
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-Semibold.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-Semibold.woff') format('woff'),
       url('/assets/public/fonts/Graphik-Semibold.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-Semibold.svg#Graphik') format('svg');
  font-weight: $fonts__semibold;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/assets/public/fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
       url('/assets/public/fonts/Graphik-SemiboldItalic.woff') format('woff'),
       url('/assets/public/fonts/Graphik-SemiboldItalic.ttf') format('truetype'),
       url('/assets/public/fonts/Graphik-SemiboldItalic.svg#Graphik') format('svg');
  font-weight: $fonts__semibold;
  font-style: italic; 
}