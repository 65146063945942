.tabs {
  &__waypoint {
    &--sticky {
      padding-top: 66px;
    }
  }

  &__container {
    position: relative;
    //max-width:1160px;
    transition: transform .5s;
    background: $colors__white;

   
    &__underline {
      height: 2px;
      margin: 0;
      background: $colors__blue--dark;
      border: none;
      transition: .3s ease-in-out;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }

    &__menu {

      @include container();
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &:after,
      &:before {
        z-index: 2;
        content: "";
        display: block;
        position: absolute;

        top: 0;
        height: 100%;
        width: 2.5rem;
        //background-image: linear-gradient(transparent, #FFF) left repeat; 

        //background: -webkit-linear-gradient(to right, transparent, rgba(255,255,255,1) 100%);
        pointer-events: none;
      }

      &:after {
        right: 0px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, rgba(255, 255, 255, 1) 100%);
      }

      &:before {
        left: 0px;
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0.001) 0%, rgba(255, 255, 255, 1) 100%);
      }
      &__tab-list {
        display: inline-flex;
        flex-wrap: nowrap;
        position: relative;
        min-width: 100%;
        &:after {
          content: '';
          @include container();
          height: 2px;
          background-color: $colors__smoke;
          z-index: 1; //be above gradient so this doesn't fade
          display: block;
          margin-top: -2px;
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
    
      }
    
      &__tab {
        white-space: nowrap;
        cursor: pointer;

        &:focus {
          outline: 0;
        }

        &:hover {
          color: $colors__blue--dark;
        }

        @extend .font__body2;
        @extend .font__body2--semibold;
        
        padding: 24px 4px;
        padding-bottom: 16px;
        position: relative;
        border-bottom: solid 2px rgba(0, 0, 0, 0);
        transition: color .3s ease-in-out,
        border .3s ease-in-out;
        z-index: 1;

        h2{
          @extend .font__body2;
          @extend .font__body2--semibold;
        }
        
        &--active {
          color: $colors__blue--dark;
        }

        &:not(:first-child) {
          margin-left: $link-spacing;
        }
      }
    }

    &--sticky {
      position: fixed;
      top: 66px;
      z-index: 9;
      display: block;
      background: white;
      width: 100%;
      border-top: 2px solid $colors__smoke;
    }
  }



  &--sticky {
    position: fixed;
    top: 66px;
    z-index: 9;
    display: block;
    background: white;
    width: 100%;
  }

  &--hide {
    transform: translateY(-66px);
    transition: transform .5s;
    border-top: none;
  }

  &--with-gradient {
    margin-left: -40px;
    padding-left: 40px;
  }







  &__filler {
    padding-right: 100px;
  }

  &__bar {
    height: 2px;
    background-color: $colors__lines;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @include below(mobile) {
    &__container {
      &--hide{
        display: none;
      }
    }
  }

  @include above(mobile) {
    &__menu {
      display: inline-flex;
    }
  }

  @include above(tablet) {
    &__container {
      &__menu{
        overflow: hidden;
      }
    }

    &__menu {      
      &:after {
        content: none;
      }
    }
  }
}