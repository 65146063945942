
// $easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

$transition__timing: 0.35s;
$transition__ease: ease-out;
$hover-speed: 150ms;

// $transition__bezier: $easeInOutQuint;



//vue transition
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}