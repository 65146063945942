.highlights {
  width: 100%;
  
  &__title {
    @extend .font__h2;
  }
  &__items {
    margin-top: 24px;
  }
  &__item {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  &__label {
    @extend .font__body;
    @extend .font__body--semibold;
	
	margin-bottom: 10px;
  }
  &__content {
    @extend .font__body2;
  }

  @include above(desktop) {
    &__items {
      display: flex;
      margin-top: 32px;
    }
    &__item {
      flex: 1;
      &:not(:first-child) {
        margin-top: 0;
        margin-left: 36px;
        border-left: solid 1px $colors__lines;
        padding-left: 36px;
      }
    }

    &--no-borders{
      .highlights__item {
        &:not(:first-child) {
          border: none;
        }
      }
    }
  }
}