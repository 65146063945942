.modal {
  @extend .font__body;
  position: fixed;
  top: calc(#{$navbar-height} + 20px);
  left: 0px;
  top: 0;
  background-color: rgba($colors__gray--modal, .9);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  
  &__close {
    cursor: pointer;
    align-self: flex-end;
  }
  &__inner {
    background-color: $colors__white;
    width: 70%;
    margin: 0 auto;
    padding: 30px;
overflow: auto;
    display: flex;
    flex-direction: column;
	align-items: flex-start;
	max-height: 90%;
    &__text {
      margin-top: 46px;
	  width: 100%;
    }
    &__form-modal{
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;

    }
    h2 {
      &.modal__inner__header{
        @extend .font__h2;
      }
    }
    &__header{
      
      margin-bottom: $mobile-vertical-padding;
    }
    &__subheader{
      margin-bottom: $desktop-vertical-padding;
    }
  }

  @include below(tablet) {
    &__inner {
      width: 100%;
      height: 100%;

      &__header{
        margin-bottom: $desktop-vertical-padding;
      }

      &__subheader{
        margin-bottom: 40px;
      }

      &__text {                
        margin-top: $mobile-vertical-padding;

        &.rich-text {
          font-size: 1rem;
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
