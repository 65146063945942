.blog {
  &-about {
    width: 100%;
  }
  &-slide {
    background-size: cover;
    background-position: center;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    width: 100%;
    
    &__left {
      position: relative;
      //@include container();
      padding-top: 72px;
      padding-bottom: 72px;
      padding-left:60px;
      color: $colors__white;
      &__date {
        margin-bottom: 12px;
      }
      &__eyebrow {
        margin-bottom: 12px;
      }
      &__header {
        @extend .font__h2;
      }
    }
  }
  @include above(desktop) {
    &-slide {
      &__left {
        padding-top: 144px;
        padding-bottom: 144px;
      }
    }
  }
}
.blog-carousel {
  overflow: hidden;
  position: relative;
  &__left-arrow {
    left:15px;
  }
  &__right-arrow {
    right:15px;
  }
}
.blog-search {
  margin-top: 24px;
}