.menu {
  $navbar-height: 66px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: $colors__blue;
  color: $colors__white;
  transition: transform .6s ease-in-out;

  &--navbar-above {
    padding-top: $navbar-height;
    transform: translateY(-100%);
  }
  &--navbar-below {
    padding-bottom: $navbar-height;
    transform: translateY(100%);
  }
  &--visible {
    transform: none;
  }

  &__content {
    box-sizing: border-box;
    padding: 40px 20px;
    padding-top: 120px;
    height: 100%;
    overflow: auto;
  }
  &__links {
    display: flex;
    flex-direction: column;
    &--secondary {
      margin-top: 36px;
    }
  }
  &__link-item {
    &:not(:first-child) {
      margin-top: 14px;
    }
    &--primary {
      padding-bottom: 12px;
      border-bottom: solid 1px rgba($colors__white, .5);
    }
  }
  &__link {
    @include fadeOnHover();
    &--primary {
      @extend .font__h1;
    }
    &--secondary {
      @extend .font__body;
    }
  }
  &__search {
    margin-top: 64px;
  }

  @include above(desktop) {
    display: none;
  }
}