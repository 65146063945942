.minimal-input {
  padding-top: .4em;
  padding-bottom: .2em;
  border-bottom: 1px solid;
  border-color: inherit;
  width: 100%;
  background-color: transparent;
  position: relative;

  &--teal-dark {
    color: $colors__teal--dark;

    .minimal-input__input {

      @include placeholder {
        color: $colors__teal--dark;
      }
    }
  }

  &__placeholder {
    position: absolute;
    left: 0;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
  }

  &__input {
    height: 1.5em;
    width: 100%;
  }

}
