.rss {
    &__landing {
        .section--component {
            padding-top: $desktop-vertical-padding;
            padding-bottom: $desktop-vertical-padding;
            border-top: solid 1px $colors__lines;                        
        }

        @include above(tablet) {

        }
    }
}