.section {
  
  padding-top: 32px;
  padding-bottom: 32px;
  &--component {
    >* {
      width:100%;
    }
  }

  &__event-landing {
    &:first-child{
      padding-top: 32px;
    }
  }
  
  &--spacing-none {
    padding: 0;
	margin: 0;
    
	&:not(:first-child) {
	  padding-top: 32px;
	}  
  }
  
  .section--component+&--component {
    &:not(:first-child) {
      padding-top: $desktop-vertical-padding;
      padding-bottom: $desktop-vertical-padding;
      border-top: solid 1px $colors__lines;
    }
  }

  &--component{
    &.section--force-border{
      padding-top: $desktop-vertical-padding;
      padding-bottom: $desktop-vertical-padding;
      border-top: solid 1px $colors__lines;
      margin-top: -1px;
    }
  }

  &--border-top {
    border-top: solid 1px $colors__lines;

    &--half {
      &:not(:first-child) {
        position: relative;

        &:before {
          content: '';
          width: 100%;
          position: absolute;
          right: 0;
          height: 1px;
          background: $colors__lines;
          top: 0;
        }
      }
    }
  }

  &__aside {
    padding-bottom: 32px;
    border-bottom: solid 1px $colors__lines;
    margin-bottom: 32px;
    .link {
      margin-bottom: 2px;
    }
  }

  //for single column sections - width 100% for IE 
  &__single-column-inner {
    width: 100%;
  }

  @include above(tablet) {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 72px;
    padding-bottom: 72px;
    justify-content: space-between;
    width: 100%;

    &__event-landing {
      &:first-child{
        padding-top: 72px;
      }
    }

    &--spacing-none {
		padding: 0;
		margin: 0;
		
		&:not(:first-child) {
		  padding-top: 72px;
		} 
	  }
    .section--component+&--component {
      &:not(:first-child) {
        padding-top: 86px;
        padding-bottom: 86px;
        border-top: solid 1px $colors__lines;
      }
    }

    &--component{
      &.section--force-border{
        padding-top: 86px;
        padding-bottom: 86px;
      }
    }

    &--border-top {
      &--half {
        &:not(:first-child) {
        &:before {
          width: 63%;
        }
      }
      }
    }

    &__single-section{
      flex-direction: row;
    }

    &__main {
      width: 66%;
	  
	  &--full {
		width: 100%;
	  }
    }

    &__aside {
      //padding-left: 120px;
      width: 25%;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
      flex-shrink: 1;
    }
  }

}