.regular-input {
  margin: 16px 0;
  margin-right: 1.5rem;
  position: relative;
  //@include transition(background-color);
  width: 100%;
  outline: 1px solid $colors__gray--light;
  @extend .font__body2;

  &__search {
    @extend .icon;
    @extend .icon__search;
    width: 100%;
    display: inline-flex;
    align-items: center;
    position: relative;

    &__input {
      @extend .font__subhead1;
      @extend .font__subhead1--extralight;
      margin-left: 10px;

      &::placeholder {
        @extend .font__subhead1;
        @extend .font__subhead1--extralight;
        color: $colors__black;
      }

    }

    &:focus-within {
      border-color: $colors__teal;
    }
  }

  &--active {
    outline-color: $colors__teal;
    .icon__close {
      color:  $colors__teal;
    }
  }

  &::before {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
  }

  &--search {
   
    &:focus-within {
      outline-color: $colors__teal;
    }
    &--active {


      &:before {
        content: none;
      }

    }

    &__clear {
      //color: $colors__teal;
      position: absolute;
      right: 0;
      top: 50%;

      transform: translateY(-50%);
      margin-right: 0.9375rem;
    }
  }

  &__clear {
    color: $colors__teal;
    //margin-right: 15px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    @include transition(opacity);
    cursor: pointer;

    &:hover {
      opacity: .5;
      @include transition(opacity);
    }
  }

  &__input {
    @extend .font__body2;
    padding: 14px 14px;
    //padding-left: 36px;

    color: $colors__black;
    width: 100%;

    &:focus {
      outline: none;
    }

    @include placeholder {
      color: $colors__black;
    }
  }

  &__datepicker {
    @extend .font__body2;
    padding: 14px;
    color: $colors__black;
    width: 100%;    
    background: transparent; 
    @include transition(background-color);
    position: relative;

    input {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      border: none;
    }
  }

@include above(mobile) {
  width: auto;
}
  @include above(desktop) {
   

    display: inline-block;
    width: auto;


    &::after {
      top: 18px;
    }

    &__input {
      width: auto;
    }

  }

}

//hide edge x buttons

input {
  &::-ms-clear {
    display: none;
  }
}