blockquote {
		@extend .font__body;
		position:relative;
      &:before {
        @include font-family-icomoon();
        content: $icon__quotes;
        font-size: 30px;
		    color: $colors__coral;
        display:block;
        margin-bottom: 25px;
      }

		p {
			&:first-child {
				display: inline;
			}
		}
	  }
	  
	  