
.animated {
  -webkit-animation-duration: 0.5s;
     -moz-animation-duration: 0.5s;
       -o-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
     -moz-animation-fill-mode: both;
       -o-animation-fill-mode: both;
          animation-fill-mode: both;
  will-change: transform, opacity;
}
@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slideDown {
  0% {
    -moz-transform: translateY(-100%);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes slideDown {
  0% {
    -o-transform: translateY(-100%);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.animated.slideDown, .table-slideDown  .sticky-table-header.fixed-header.animated {
  -webkit-animation-name: slideDown;
  -moz-animation-name: slideDown;
  -o-animation-name: slideDown;
  animation-name: slideDown;
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100%);
  }
}
@-moz-keyframes slideUp {
  0% {
    -moz-transform: translateY(0);
  }

  100% {
    -moz-transform: translateY(-100%);
  }
}
@-o-keyframes slideUp {
  0% {
    -o-transform: translateY(0);
  }

  100% {
    -o-transform: translateY(-100%);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}
.animated.slideUp {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  -o-animation-name: slideUp;
  animation-name: slideUp;
}