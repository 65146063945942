.video {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  //margin-left: -20px;
  //margin-right: -20px;
  &--one,
  &--two,
  &--three {
    .video__container {
      width: 100%;
    }

  }

  &__container {
    position: relative;
    margin-bottom: $mobile-vertical-padding;

    &--pull {

      display: flex;
      flex-direction: column;
    }

    &__caption {
      @extend .font__body2;

    }

    &__preview {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      padding: 20px;
      //pointer-events: none;
      background-size: cover;
      background-position: center;
      &--hidden {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s linear 300ms, opacity 300ms;
      }

      &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: white;
        border-radius: 50%;
        border: 1px solid white;
        background-color: rgba(#000, .3);
        padding: 14px 10px 14px 14px;
        font-size: 30px;
        cursor: pointer;
        @include transition(transform);
        display: flex;
        &:hover {
          transform: translateX(-50%) translateY(-50%) scale(1.25);
        }
      }
    }

    &__frame {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
      margin-bottom: 1em;

      &--pull {
        padding-top: 38.25%;
      }

      iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  @include above(desktop) {
    flex-direction: row;
	&--two {
      .video__container {
        width: 50%;
      }
    }

    &--three {
      .video__container {
        width: 33.33%;
      }
    }

    &--two,
    &--three {
      margin-left: -20px;
      margin-right: -20px;
    }

    &__container {
      padding: 20px;

      &__caption {
        &--pull {
		  flex-basis: 33%;
		}
      }

      &__frame {
        &--pull {
          width: 100%;
          margin-right: 40px;
        }
      }
	  
	  &--pull {
	  flex-direction: row;
	  }
    }
  }
}
