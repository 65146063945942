
.award-full-logo {
    @include themify($themes) {
      background: themed('color');  
    }    

}
.award {

  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 260px 135px;
	
	&--full-bg {
	  background-size: cover;
	}
    @include above(tablet) {
      background-size: 420px 270px;
	  
	  &--full-bg {
		  background-size: cover;
		}
      img {
        max-width: 420px;
        max-height:270px;
  
      } 
    }
  
  }
}
.award-recognition {
  width: 100px;
  height: auto;
  margin-bottom:24px;
  margin-right:12px;
  img {
    width:100%;
  }
}
.award-recognition-container {
  align-items: center;
  display:flex;
  flex-direction: row;
  margin:25px 60px 0 30px;
  min-width:100px;
  //padding: 25px 0; 
  @include above(tablet) {
    flex-direction: column;
  }
  @include above(desktop) {
    //margin-left: -150px;
  }
}