.interactive-maps{
  &__tabs{
    margin-bottom: $mobile-vertical-padding;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  &__tab{
    color: $colors__gray;
    margin-right: 40px;
    @extend .font__body3;

    &:last-child{
      margin-right: 0;
    }
    &--active{
      color: $colors__black;
    }

    &:hover{
      color: $colors__black;
    }
  }
  &__map-wrapper {
    background-color: #E6E7E9;
    padding: 20px 0;
  }
  &__map{
    width: 100%;
    height: 300px;
    background-color: #E6E7E9;
  }
  &__download-wrapper{
    margin-top: $mobile-vertical-padding;
    display: flex;
    justify-content: flex-end;
  }

  @include above(desktop) {
    &__map{
      height: 500px;
    }
  }
}