.office-contact {
  margin-bottom: $mobile-vertical-padding;
  line-height:1.4;
  &__address {
    max-width: 260px;
    line-height:1.4;
    
    display:flex;
    &__icon {
      margin-right:10px;
      &:before {
        @extend .font__body2--semibold;
        margin: -3px;
      }
    }
    &__address {
      position:relative;
    top: -4px;

    }
     
  }
  &__telephone {
    display:flex;
	line-height: 1.4;
    &__label {
      @extend .font__body2--semibold;
      margin-right:10px;
    }
  }
  &__fax {
    display:flex;
    &__label {
      @extend .font__body2--semibold;
      margin-right:10px;
    }
  }
}