.cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $colors__gray--modal;
    z-index: 5;

    &__inner {
        display: flex;
        margin: 1rem auto;
        width: 75%;

        &__text {
            font-size: 18px;
            font-weight: 500;
            margin-right: 100px;
        }

        &__button {
            display: flex;
            align-items: center;
        }
    }

    &__alternate {
        background-color: $colors__coral--dark;
        opacity: 0.9;

        .cookie__inner {
            &__text {
                color: $colors__white;
                a {                    
                    @include transition(color, opacity $hover-speed ease-in-out);
                    &:hover {
                        opacity: .6;
                        color: $colors__white !important;
                    }
                }
            }

            &__button {
                button {
                    color: $colors__white;
                }
            }
        }
    }

    @include below(xl) {
        &__inner {            
            width: 90%;

            &__text {                
                margin-right: 50px;
            }
        }
    }

    @include below(tablet) {
        &__inner {            
            width: 95%;
            margin: 1rem;
            display: block;

            &__text {                
                margin-right: 0;
                margin-bottom: 1rem;
            }
        }
    }
}