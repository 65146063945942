//the three inner panels for the homepage inquiry 
//I'm looking for X Y and Z

.homepage-inquiry-panel {
  position: absolute;
  @include transition(top);
  opacity: 0;
  left:0;
  top: 100%; //be totally off the screen
  width: 100%;
  overflow: hidden; //let scrollable do all the scrolling

  //allow one of the child elements to shrink and scroll
  display: flex;
  flex-direction: column;

  height:0;
  &--blue {
    background-color: $colors__blue;
  }

  &.active {
    //this is the top margin, the height of the dropdown, and the bottom margin
    //and hope it never breaks to two lines
    $dropdown-gap: $homepage-inquiry-dropdown-height  + 2 * $homepage-inquiry-vertical-padding;
    top: $dropdown-gap;
    bottom: 0;
    opacity: 1;
    height: 100%;
    height: calc(100% - #{$dropdown-gap});
    z-index: 10;
    &.dropdown {
      $dropdown-gap: $homepage-inquiry-dropdown-height  + 2 * $homepage-inquiry-vertical-padding + 110px;
      top: $dropdown-gap;
      bottom: 0;
      opacity: 1;
      height: 100%;
      height: calc(100% - #{$dropdown-gap});
    }
  }

  &__top {
    padding: 1em $homepage-panel-side-padding;
    background-color: $colors__blue;
    color: $colors__white;
    @extend .font__body2--semibold;
  }

  &__scrollable {
    padding: 0 $homepage-panel-side-padding 1em $homepage-panel-side-padding;
    position: relative;
    background-color: $colors__white;
    flex-shrink: 3;
    overflow-x: none;
    overflow-y: scroll;
    // overflow: scroll;
    height:100%;
    @include hide-scrollbar();

    &--blue {
      background-color: $colors__blue;
    }
  }
  
  &__close {
    float: right;
  }

  &__people-search, &__other-search {
    padding: 0 $homepage-panel-side-padding 5px $homepage-panel-side-padding;
    background-color: $colors__white;
    @extend .font__subhead1;
    @extend .font__subhead1--extralight;

    //TODO: THIS IS NOT ACCESSIBLE but design really wanted it
    input {
      outline: none;
    }
  }

  @include above(desktop) {
    top:0; 
    right: 0;
    transform: translateX(100%);
    @include transition(transform);

    overflow: auto;

    &.active {
      transform: none;
      top: 0;
      left: auto;
      right: 0;
      height: 100%;
      width: 33%;
    }

    &__top {
      padding: 32px $homepage-panel-side-padding-desktop;
    }

    // we want just this panel to scroll, not the whole panel
    &__scrollable {
      flex-shrink: 2;
      flex-grow: 1;
      overflow: auto;
      padding-left: $homepage-panel-side-padding-desktop;
      padding-right: $homepage-panel-side-padding-desktop;
    }

    &__people-search, &__other-search {
      padding: 0 $homepage-panel-side-padding-desktop;
      padding-top: 20px;
    }
  }
}