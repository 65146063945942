.insight-card-container {

    //do nothing on mobile

    
    // 2 columns
    @include above(tablet) {
        margin-left: -5px;
        margin-right: -5px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .insight-card {
            width: 50%;
        }
    }
    
    // 3 columns
    @include above(desktop) {
        .insight-card {
            width: 33%;
        }
    }

   
}